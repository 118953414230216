import React from "react";
import styles from "./loader.module.scss";

const Loader: React.FC<{ message?: string }> = ({ message = "We're processing your request. please wait..." }) => {
  return (
    <div className={styles.loaderWrapper}>
      <div className={styles.pulsingCircle}></div>
      <p className={styles.loaderMessage}>{message}</p>
    </div>
  );
};

export default Loader;

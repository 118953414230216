import React from "react";
import styles from "./breadcrumbs.module.scss";
import { Breadcrumbs, IconButton, Link, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { IRouteConfig, IBreadcrumbsProps } from "../../interfaces";
import Icon from "@mdi/react";
import { mdiArrowLeft, mdiSlashForward } from "@mdi/js";

const BreadcrumbsComponent: React.FC<IBreadcrumbsProps> = ({
  routes,
  onClick,
}) => {
  const location = useLocation(); // Get current path
  const navigate = useNavigate(); // Hook to navigate back
  const currentPath = location.pathname;

  // Function to find the route from a given path segment
  const findRoute = (
    routes: IRouteConfig[],
    pathSegment: string
  ): IRouteConfig | undefined => {
    return routes.find((route) => route.path === pathSegment);
  };

  // Find the Dashboard route (base route '/')
  const dashboardRoute =
    currentPath === "/inventory" || currentPath === "/inventory/tag-details"
      ? routes.find((route) => route.path === "/ICM")
      : currentPath === "/tag-details"
      ? routes.find((route) => route.path === "/user-dashboard")
      : routes.find((route) => route.path === "/");

  // Build the breadcrumb trail based on the current path segments
  const getBreadcrumbs = (
    routes: IRouteConfig[],
    currentPath: string
  ): IRouteConfig[] => {
    const pathSegments = currentPath.split("/").filter(Boolean); // Split path into segments
    let breadcrumbs: IRouteConfig[] = [];

    // Always include the Dashboard route at the start of the breadcrumbs (except when on dashboard page)
    if (dashboardRoute && currentPath !== "/") {
      breadcrumbs.push(dashboardRoute);
    }

    let currentRoutes = routes;

    for (let i = 0; i < pathSegments.length; i++) {
      const fullPath = `/${pathSegments.slice(0, i + 1).join("/")}`;
      const matchedRoute = findRoute(currentRoutes, fullPath);

      if (matchedRoute) {
        breadcrumbs.push(matchedRoute);
        if (matchedRoute.children) {
          currentRoutes = matchedRoute.children; // Move to child routes if any
        }
      }
    }

    return breadcrumbs;
  };

  // Get the breadcrumb trail for the current path
  const breadcrumbs = getBreadcrumbs(routes, currentPath);

  // Handle breadcrumb navigation click
  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    path: string
  ) => {
    event.preventDefault();
    if (onClick) {
      onClick(event, path);
    } else {
      navigate(path); // Navigate to the clicked path
    }
  };

  // Handle back button click
  const handleBackClick = () => {
    navigate(-1); // Navigate back one page
  };

  return (
    <div className={styles.breadcrumbs}>
      {/* Back Arrow Button: only show when not on the Dashboard page */}
      {currentPath !== "/" && (
        <IconButton
          onClick={handleBackClick}
          aria-label="back"
          sx={{ mr: 0.5, pl: 0 }}
          className={styles.breadcrumb_arrow}
        >
          <Icon path={mdiArrowLeft} size={0.8} />
        </IconButton>
      )}

      {/* Breadcrumbs Navigation */}
      {currentPath !== "/" && (
        <Breadcrumbs
          separator={<Icon path={mdiSlashForward} size={0.7} />}
          aria-label="breadcrumb"
        >
          {breadcrumbs.map((route, index) => {
            const isLast = index === breadcrumbs.length - 1;
            return isLast ? (
              <Typography className={styles.breadcrumbs_last} key={route.path}>
                {route.menuName || route.title}
              </Typography>
            ) : (
              <Link
                key={route.path}
                href={route.path}
                onClick={(event) => handleClick(event, route.path)}
                className={styles.breadcrumbs_list}
              >
                {route.menuName || route.title}
              </Link>
            );
          })}
        </Breadcrumbs>
      )}
    </div>
  );
};

export default BreadcrumbsComponent;

import React, { Suspense, useEffect, useState } from "react";
import { Alert, Box, Grid, Snackbar } from "@mui/material";
import styles from "./userStateCount.module.scss";
import { GridColDef, GridColumnGroupingModel } from "@mui/x-data-grid";
import { CustomPagination, Datatable, Loader } from "../../../components";
import { mdiCheck, mdiClose, mdiAlertCircleOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { dashboardService } from "../../../api/services";
import { UserRoles } from "../../../utils/Enum/UserRole";
import { useClient } from "../../../utils/ClientContext";
import { IUserState } from "../../../interfaces";

const UserStateCount: React.FC<IUserState> = ({ inventoryId }) => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = React.useState(0);
  const [error, setError] = useState<string | null>(null);
  const { client } = useClient();
  const clientId = client.id;
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error">("success");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [rows, setRows] = useState<any[]>([]);
  const [pageSize, setPageSize] = React.useState(5);
  const [pageCount, setPageCount] = React.useState(0);
  const [showLoader, setShowLoader] = useState(false);
  // Dynamically assign custom icons based on severity
  const getIcon = () => {
    if (severity === "success") {
      return <Icon path={mdiCheck} size={1} />; // Custom success icon
    }
    return <Icon path={mdiAlertCircleOutline} size={1} />; // Custom error icon
  };

  //Close Snackbar
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  useEffect(() => {
    fetchCounterUserStateData();
  }, [page]);
  //Fetch Inventory Completion Data
  const fetchCounterUserStateData = async () => {
    try {
      setShowLoader(true);
      const payload = {
        clientId: clientId,
        role: UserRoles.Counter,
        inventoryId: inventoryId,
        currentPage: page + 1,
        pageSize: pageSize,
      };
      const response = await dashboardService.getUserStatusAsync(payload);
      if (response.isSuccess) {
        const formattedRows = response.userStatus.map((user: any) => ({
          id: user.userId,
          userName: `${user.firstName} ${user.lastName}`,
          counts: user.totalAssignedTags,
          remainingTags: user.remainingTags,
          completedTags: user.completedTags,
          erroredTags: user.errorTags,
          accuracy:
            user.totalAssignedTags === 0
              ? "--"
              : `${Math.ceil(user.accuracy)}%`, // Show accuracy even if it's 0
          percentageCompletion:
            user.totalAssignedTags === 0
              ? "--"
              : `${Math.ceil(user.percentageCompletion)}%`,
        }));
        setRows(formattedRows);
        setPageCount(Math.ceil(response.totalRecords / pageSize));
      }
    } catch (error) {
      setSeverity("error");
      setSnackbarMessage("Failed to load User Status");
      setOpenSnackbar(true);
    } finally {
      setShowLoader(false);
    }
  };
  // Handles the page change event
  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };
  const handleFirstPage = () => {
    setPage(0);
  };

  const handleLastPage = () => {
    setPage(pageCount - 1);
  };

  const handleNextPage = () => {
    if (page < pageCount - 1) {
      setPage((prev) => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 0) {
      setPage((prev) => prev - 1);
    }
  };
  const columns: GridColDef[] = [
    { field: "userName", headerName: " ", width: 200, minWidth: 200, flex: 2 },
    {
      field: "counts",
      headerName: "Counts",
      width: 130,
      minWidth: 130,
      flex: 1.2,
    },
    {
      field: "remainingTags",
      headerName: "Remaining Tags",
      width: 130,
      minWidth: 130,
      flex: 1.2,
    },
    {
      field: "completedTags",
      headerName: "Completed Tags",
      width: 130,
      minWidth: 130,
      flex: 1.2,
    },
    {
      field: "erroredTags",
      headerName: "Errored Tags",
      width: 130,
      minWidth: 130,
      flex: 1.2,
    },
    {
      field: "accuracy",
      headerName: "Accuracy (%)",
      width: 130,
      minWidth: 130,
      flex: 1.2,
    },
    {
      field: "percentageCompletion",
      headerName: "Percentage Completion (%)",
      width: 200,
      minWidth: 200,
      flex: 2,
    },
  ];

  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: "userInfo",
      headerName: "User",
      headerAlign: "center",
      children: [{ field: "userName" }],
    },
    {
      groupId: "countStatistics",
      headerName: "Count Statistics",
      headerAlign: "center",
      children: [
        { field: "counts" },
        { field: "remainingTags" },
        { field: "completedTags" },
        { field: "erroredTags" },
        { field: "accuracy" },
        { field: "percentageCompletion" },
      ],
    },
  ];
  return (
    <Grid item xs={12} className={styles.datagrid_container}>
      <Datatable
        rows={rows}
        columns={columns}
        page={page}
        rowsPerPage={rowsPerPage}
        pagination
        paginationMode="server"
        onPageChange={handlePageChange}
        rowCount={count}
        loading={showLoader}
        error={error}
        checkboxSelection={false}
        columnGroupingModel={columnGroupingModel}
      ></Datatable>
      <Suspense fallback={<div>Loading...</div>}>
        <CustomPagination
          page={page}
          pageCount={pageCount || 1}
          handleFirstPage={handleFirstPage}
          handleLastPage={handleLastPage}
          handleNextPage={handleNextPage}
          handlePreviousPage={handlePreviousPage}
        />
      </Suspense>
      
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={10000}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={severity}
          icon={getIcon()}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {showLoader && <Loader/>}
    </Grid>
  );
};
export default UserStateCount;

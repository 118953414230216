import React, { Suspense, useCallback, useEffect, useState } from "react";
import {
  Button,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  SelectChangeEvent,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import styles from "./usersDashboard.module.scss";
import {
  CustomPagination,
  EmptyDashboard,
  FilterDropdown,
  Loader,
  SearchInput,
} from "../../components";
import { AddTagDialog, CountingTask } from "../../containers";
import { inventoryService, tagService } from "../../api/services";
import { ITagDetailsByUserRequest, IRole } from "../../interfaces";
import { useNavigate } from "react-router";
import { UserRoles } from "../../utils/Enum/UserRole";
import { useClient } from "../../utils/ClientContext";

const UsersDashboard: React.FC = () => {
  const [searchKey, setSearchKey] = useState<string>("");
  const [AddTagDialogOpen, setAddTagDialogOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState<boolean | undefined>(
    undefined
  );
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const [emptyState, setEmptyState] = React.useState(false);
  const [activeSession, setActiveSession] = React.useState(false);
  const [counterTotalTags, setCounterTotalTags] = React.useState(0);
  const [auditorTotalTags, setAuditorTotalTags] = React.useState(0);
  const { client } = useClient();
  const clientId = client.id;
  const [tags, setTags] = useState([]);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(5);
  const [pageCount, setPageCount] = React.useState(0);
  const [totalRecord, setTotalRecord] = React.useState(0);
  const userId = Number(localStorage.getItem("userId"));
  const inventoryId = Number(localStorage.getItem("inventoryId"));
  const [userName, setUserName] = useState("");

  const [selectedTab, setSelectedTab] = useState<string>("");
  const filterOptions = [
    { label: "Completed Tags", value: true },
    { label: "Incomplete Tags", value: false },
  ];
  const [isCounter, setIsCounter] = useState<boolean>(false);
  const [isAuditor, setIsAuditor] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [noDataFound, setNoDataFound] = React.useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();

  // Handle Toggle Change
  const handleToggleChange = (
    event: React.MouseEvent<HTMLElement>,
    newSelectedTab: string | null
  ) => {
    if (newSelectedTab !== null) {
      setSelectedTab(newSelectedTab);
    }
  };

  useEffect(() => {
    const storedFilter = localStorage.getItem("selectedFilterOption");
    if (storedFilter) {
      const selectedOption = filterOptions.find(
        (option) => option.value.toString() === storedFilter
      );
      setSelectedValue(selectedOption ? selectedOption.value : undefined);
    }
    const storedUserDetails = localStorage.getItem("UserDetails");
    if (storedUserDetails) {
      const userDetails = JSON.parse(storedUserDetails);
      if (userDetails && Array.isArray(userDetails.roles)) {
        setUserName(userDetails.firstName + " " + userDetails.lastName);
        const isCounterRole = userDetails.roles.some(
          (role: IRole) => role.roleId === UserRoles.Counter
        );
        if (isCounterRole) {
          setIsCounter(isCounterRole);
          setSelectedTab("counting");
        }
        const isAuditorRole = userDetails.roles.some(
          (role: IRole) => role.roleId === UserRoles.Auditor
        );
        if (isAuditorRole) {
          setIsAuditor(isAuditorRole);
          if (!isCounterRole) {
            setSelectedTab("auditing");
          }
        }
        const isAdminRole = userDetails.roles.some(
          (role: IRole) => role.roleId === UserRoles.Admin
        );
        setIsAdmin(isAdminRole);
      }
    }
    fetchActiveInventory();
    localStorage.removeItem("areaId");
    localStorage.removeItem("locationId");
    localStorage.removeItem("isCounter");
  }, [clientId]);

  // Fetch Tags By User
  const fetchTagsByUser = async () => {
    setShowLoader(true);
    try {
      const requestViewModel: ITagDetailsByUserRequest = {
        userId,
        clientId,
        inventoryId,
        isCounted: selectedValue,
        isCounter: selectedTab === "counting" ? true : false,
        searchTerm: searchKey.length >= 3 ? searchKey : "",
        pageSize: pageSize,
        currentPage: page + 1,
      };
      const response = await tagService.getTagByUserIdAsync(requestViewModel);
      if (response.data.isSuccess) {
        if (response.data.tagDeatils.length === 0) {
          setEmptyState(true);
          if (searchKey.length >= 3 || selectedValue != null) {
            setNoDataFound(true);
          } else {
            setNoDataFound(false);
          }
        } else {
          setEmptyState(false);
        }
        setAuditorTotalTags(response.data.totalAuditorTagCount);
        setCounterTotalTags(response.data.totalCounterTagCount);
        setTags(response.data.tagDeatils);
        setTotalRecord(response.data.totalRecord);
        setPageCount(Math.ceil(response.data.totalRecords / pageSize));
      } else {
        console.error("Failed to fetch tags:", response.data.errors);
      }
    } catch (error) {
      console.error("API error:", error);
    } finally {
      setShowLoader(false);
    }
  };

  // Trigger API call on filter selection change
  useEffect(() => {
    if (activeSession) {
      if ((searchKey === "" || searchKey.length >= 3) && selectedTab !== "") {
        fetchTagsByUser();
      } else if (selectedValue !== undefined) {
        fetchTagsByUser();
      }
    } else {
      setEmptyState(true);
    }
  }, [selectedValue, page, selectedTab, activeSession, searchKey]);

  // Handle change of selected value
  const handleFilterChange = (event: SelectChangeEvent<string>) => {
    const selectedLabel = event.target.value;
    const selectedOption = filterOptions.find(
      (option) => option.label === selectedLabel
    );
    setSelectedValue(selectedOption ? selectedOption.value : undefined);
    if (selectedOption) {
      localStorage.setItem(
        "selectedFilterOption",
        selectedOption.value.toString()
      );
    }
  };

  // Function to handle opening the filter dropdown
  const handleFilterOpen = () => {
    setFilterOpen(true);
  };

  // Function to handle closing the filter dropdown
  const handleFilterClose = () => {
    setFilterOpen(false);
  };

  // Function to clear the filter
  const handleFilterClear = () => {
    setSelectedValue(undefined);
    localStorage.removeItem("selectedFilterOption");
  };

  const handleTagDetails = (areaId: number, locationId: number) => {
    localStorage.setItem("areaId", areaId.toString());
    localStorage.setItem("locationId", locationId.toString());
    localStorage.setItem("isCounting", (selectedTab === "counting").toString());
    navigate("/tag-details");
  };

  //Open Location Dialog
  const onClickAddTagDialogOpen = () => {
    setAddTagDialogOpen(true);
  };

  //Close Location Dialog
  const onClickAddTagDialogClose = useCallback(() => {
    setAddTagDialogOpen(false);
  }, []);

  const fetchActiveInventory = async () => {
    setShowLoader(true);
    try {
      const data = await inventoryService.getActiveInventorySessionAsync(
        clientId
      );
      if (data.isSuccess && data?.inventory.inventoryId != 0) {
        localStorage.setItem("inventoryId", data.inventory.inventoryId);
        setActiveSession(data.isSuccess);
      } else {
        setActiveSession(false);
      }
    } catch (error) {
      console.error("Failed to fetch active inventory session:", error);
    }finally {
      setShowLoader(false);
    }
  };

  const handleFirstPage = () => {
    setPage(0);
  };

  const handleLastPage = () => {
    setPage(pageCount - 1);
  };

  const handleNextPage = () => {
    if (page < pageCount - 1) {
      setPage((prev) => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 0) {
      setPage((prev) => prev - 1);
    }
  };
  const theme = useTheme();
  const IsMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const NumoftagRem = IsMobile
    ? "Total Tags Remaining"
    : "Number of tags remaining";
  const Numoftag = IsMobile ? "Total Tags" : "Number of tags";

  return (
    <div className="content_container">
      {activeSession ? (
        <>
          <Grid
            container
            item
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item sm={9}>
              {emptyState &&
              counterTotalTags === 0 &&
              auditorTotalTags === 0 ? (
                <h3 className={styles.page_title}>
                  Welcome {userName}, Here are your tasks lined up!
                </h3>
              ) : (
                <>
                  <h3 className={styles.page_title}>Welcome {userName}</h3>
                  <p>
                    At the moment, you have{" "}
                    <strong>
                      {counterTotalTags === 0
                        ? counterTotalTags
                        : String(counterTotalTags).padStart(2, "0")}{" "}
                      assigned tags
                    </strong>{" "}
                    for counting &{" "}
                    <strong>
                      {auditorTotalTags === 0
                        ? auditorTotalTags
                        : String(auditorTotalTags).padStart(2, "0")}{" "}
                      assigned tags
                    </strong>{" "}
                    for auditing
                  </p>
                </>
              )}
            </Grid>
            {!emptyState && selectedTab === "counting" && (
              <Grid item container sm={3} justifyContent="end">
                <Button variant="contained" onClick={onClickAddTagDialogOpen}>
                  Add Tag
                </Button>
              </Grid>
            )}
          </Grid>
          <Grid container className={styles.couterAuditerContainer}>
            <Grid item xs={12} className={styles.couterAuditertab}>
              <ToggleButtonGroup
                value={selectedTab}
                exclusive
                onChange={handleToggleChange}
                aria-label="select tab"
              >
                <ToggleButton
                  value="counting"
                  aria-label="left aligned"
                  disabled={!isCounter}
                >
                  Counting ({counterTotalTags})
                </ToggleButton>
                <ToggleButton
                  value="auditing"
                  aria-label="centered"
                  disabled={!isAuditor}
                >
                  Auditing ({auditorTotalTags})
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            {(isCounter || isAuditor) && (
              <Grid
                item
                container
                xs={12}
                className={styles.searchFilterContainer}
              >
                <Grid item sm={4} xs={6.5}>
                  <SearchInput searchKey={searchKey} onChange={setSearchKey} />
                </Grid>
                <Grid item sm={2} xs={4.5}>
                  <Suspense fallback={<div>Loading...</div>}>
                    {handleFilterOpen && (
                      <FilterDropdown
                        selectedValue={
                          selectedValue !== undefined
                            ? filterOptions.find(
                                (option) => option.value === selectedValue
                              )?.label || ""
                            : ""
                        }
                        options={filterOptions.map((option) => ({
                          value: option.label,
                          label: option.label,
                        }))}
                        onChange={handleFilterChange}
                        onClear={handleFilterClear}
                        onOpen={handleFilterOpen}
                        onClose={handleFilterClose}
                        filterOpen={filterOpen}
                      />
                    )}
                  </Suspense>
                </Grid>
              </Grid>
            )}
            {emptyState ? (
              <Suspense fallback={<div>Loading...</div>}>
                {noDataFound ? (
                  <EmptyDashboard title="" subtitle="No data found!" />
                ) : selectedTab === "counting" ? (
                  <EmptyDashboard
                    title="No Tasks for You!"
                    subtitle="Wait for your Admin to assign you something!"
                    buttonText="Add Tag"
                    buttonFunction={onClickAddTagDialogOpen}
                  />
                ) : (
                  <EmptyDashboard
                    title="No Tasks for You!"
                    subtitle="Wait for your Admin to assign you something!"
                  />
                )}
              </Suspense>
            ) : (
              <>
                <Grid container className={styles.countingTaskContainer}>
                  {tags && tags.length > 0 && (
                    <Suspense fallback={<div>Loading...</div>}>
                      {tags.map((tag: any) => (
                        <CountingTask
                          locationId={tag.locationId}
                          areaId={tag.areaId}
                          areaName="Area"
                          areaTag={tag.areaName}
                          locationName="Location"
                          locationTag={tag.locationName}
                          numberOfTags={tag.tagQty}
                          onArrowClick={() =>
                            handleTagDetails(tag.areaId, tag.locationId)
                          }
                          filterText={
                            selectedValue === false ? NumoftagRem : Numoftag
                          }
                        />
                      ))}

                      <CustomPagination
                        page={page}
                        pageCount={pageCount || 1}
                        handleFirstPage={handleFirstPage}
                        handleLastPage={handleLastPage}
                        handleNextPage={handleNextPage}
                        handlePreviousPage={handlePreviousPage}
                      />
                    </Suspense>
                  )}
                </Grid>
              </>
            )}
          </Grid>

          <Suspense fallback={<div>Loading...</div>}>
            {AddTagDialogOpen && (
              <AddTagDialog
                open={AddTagDialogOpen}
                onClose={onClickAddTagDialogClose}
                onSubmit={fetchTagsByUser}
              />
            )}
          </Suspense>
        </>
      ) : (
        <>
          <h3 className={styles.page_title}>Welcome {userName}</h3>
          <p>At the moment, <strong>No Inventory Session</strong> is active.</p>
        </>
      )}
       {showLoader && <Loader/>}
    </div>
  );
};
export default UsersDashboard;

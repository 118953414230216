import React, { useCallback, useEffect, useState } from "react";
import { NavLink, useLocation, useMatch, useNavigate } from "react-router-dom";
import styles from "./navigationBar.module.scss";
import Icon from "@mdi/react";
import {
  Alert,
  Button,
  Drawer,
  FormControl,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
  styled,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  mdiAccountCircleOutline,
  mdiAlertCircleOutline,
  mdiCheck,
  mdiChevronLeftCircle,
  mdiClose,
  mdiLogout,
  mdiMenu,
  mdiPlus,
} from "@mdi/js";
import {
  IBreadcrumbsProps,
  IClient,
  ICustomNavLinkProps,
  IRole,
  IUser,
} from "../../interfaces";
import { useDrawer } from "../../utils/DrawerContext";
import { clientService, userService } from "../../api/services";
import { UserRoles } from "../../utils/Enum/UserRole";
import { useClient } from "../../utils/ClientContext";
import { AddClientDialog } from "../../containers";
import { useAuth } from "../../utils";

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})<{ open: boolean }>(({ theme, open }) => ({
  width: open ? 240 : 56,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  [theme.breakpoints.down("sm")]: {
    width: open ? "100%" : 0, // Adjust this width for small screens
  },
  "& .MuiDrawer-paper": {
    width: open ? 240 : 56,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
    top: "70px",
    height: "calc(100vh - 70px)",
    zIndex: "999",
    [theme.breakpoints.down("sm")]: {
      width: open ? "100%" : 0, // Adjust this width for small screens
      top: "0",
      height: "100vh",
    },
  },
}));

const StyledListItemText = styled(ListItemText)({
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  marginLeft: "15px",
  "& .MuiListItemText-primary": {
    fontWeight: "700",
    fontSize: "14px",
  },
});

const StyledListItem = styled(ListItem)({
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  padding: "0px",
});

const DrawerHeader = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "0px 20px",
});

const NavigationBar: React.FC<IBreadcrumbsProps> = ({ routes }) => {
  const { drawerOpen, toggleDrawer } = useDrawer();
  const theme = useTheme();
  const IsMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { setClient } = useClient();
  const [addClientDialogOpen, setAddClientDialogOpen] = React.useState(false);
  const [clients, setClients] = useState<IClient[]>([]);
  const [user, setUser] = useState<IUser>();
  const storedClientId = localStorage.getItem("clientId");
  const [clientId, setClientId] = useState<number>(
    storedClientId ? Number(storedClientId) : 0
  );
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [showCompanyBtn, setShowCompanyBtn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error">("success");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const userId = localStorage.getItem("userId");
  const [noAccessDialog, setNoAccessDialog] = useState(false);
  const [userLoading, setUserLoading] = useState(true);
  const [clientsLoading, setClientsLoading] = useState(true);
  const location = useLocation(); // Get the current location
  const navigate = useNavigate();
  const isLoginPage = location.pathname === "/login";
  const { logout, isAuthenticated } = useAuth();

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const handleRefresh = () => {
    fetchClients();
  };

  useEffect(() => {
    if (!isLoginPage && isAuthenticated) {
      fetchClients();
      fetchUser();
    }
  }, [userId]);
  // Dynamically assign custom icons based on severity
  const getIcon = () => {
    if (severity === "success") {
      return <Icon path={mdiCheck} size={1} />; // Custom success icon
    }
    return <Icon path={mdiAlertCircleOutline} size={1} />; // Custom error icon
  };
  const fetchClients = async () => {
    try {
      setClientsLoading(true);
      const response = await clientService.fetchClients(userId);
      setClients(response.clients);

      const existingClient = response.clients.find(
        (client: IClient) => client.id === clientId
      );
      if (existingClient) {
        setClientId(existingClient.id);
        setClient(existingClient);
        setShowCompanyBtn(false);
      } else if (response.clients.length > 0) {
        setClient(response.clients[0]);
        localStorage.setItem("clientId", response.clients[0].id.toString());
        localStorage.setItem("clientName", response.clients[0].name);
        setClientId(response.clients[0].id);
        setShowCompanyBtn(false);
      } else {
        setShowCompanyBtn(true);
      }
    } catch (error) {
      setSnackbarMessage("Failed to get Clients");
      setSeverity("error");
      setOpenSnackbar(true);
    } finally {
      setClientsLoading(false);
    }
  };

  //Close Snackbar
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const fetchUser = async () => {
    try {
      setUserLoading(true);
      const response = await userService.fetchUserById(userId);
      setUser(response);

      if (response.isSuccess) {
        localStorage.setItem("UserDetails", JSON.stringify(response));

        // Check if roles array is empty, and if so, set no access dialog
        if (!response.roles || response.roles.length === 0) {
          setNoAccessDialog(true);
        } else {
          // Check if user is SuperAdmin or Admin
          const isSuperAdmin = response.roles.some(
            (role: IRole) => role.roleId === UserRoles.SuperAdmin
          );
          setIsSuperAdmin(isSuperAdmin);

          setIsAdmin(
            response.roles.some(
              (role: IRole) => role.roleId === UserRoles.Admin
            )
          );
        }
      }
    } catch (error) {
      console.error("Error fetching user details", error);
      setSnackbarMessage("Failed to get User details");
      setSeverity("error");
      setOpenSnackbar(true);
    } finally {
      setUserLoading(false);
    }
  };

  const handleClient = (event: SelectChangeEvent) => {
    const selectedId = Number(event.target.value);
    const selectedClient = clients.find((client) => client.id === selectedId);

    if (selectedClient) {
      setClientId(selectedId);
      setClient(selectedClient);
      localStorage.setItem("clientId", JSON.stringify(selectedId));
      localStorage.setItem("clientName", selectedClient.name);
    }
    if (IsMobile) toggleDrawer(false);
  };
  const onClickAddClientDialogOpen = () => {
    setAddClientDialogOpen(true);
  };
  const onClickAddClientDialogClose = useCallback(() => {
    setAddClientDialogOpen(false);
  }, []);

  const toggleDrawerFalse = () => {
    if (IsMobile) toggleDrawer(false);
  };
  const CustomNavLink: React.FC<ICustomNavLinkProps> = ({
    to,
    children,
    exact = false,
  }) => {
    const match = useMatch({ path: to, end: exact });

    return (
      <NavLink to={to} className={match ? styles.active : ""}>
        {children}
      </NavLink>
    );
  };

  const isMat2rixPage =
    location.pathname === "/" ||
    location.pathname === "/users" ||
    location.pathname === "/my-profile"; // Check if current path is login

  const isUserDashboard =
    location.pathname === "/user-dashboard" ||
    location.pathname === "/tag-details";
  const routesElement = React.useMemo(() => {
    return routes.map((route, index) => {
      const isMat2rix = route.isMat2rix ?? false;
      if (
        !(isAdmin || isSuperAdmin) &&
        (route.path === "/settings" || route.path === "/ICM")
      ) {
        return;
      }
      if ((isAdmin || isSuperAdmin) && route.path === "/user-dashboard") {
        return;
      }
      if (!route.isShow) return null; // Skip if isShow is false
      if (isMat2rix && isMat2rixPage) {
        if (!(isAdmin || isSuperAdmin) && route.path === "/users") {
          return;
        }
        // Render items when isMat2rix is true
        return (
          <StyledListItem
            key={`mat2rix-${index}`} // Use a unique key
            className={styles.navItem}
            onClick={IsMobile ? toggleDrawerFalse : undefined}
          >
            <CustomNavLink to={route.path} exact>
              <Tooltip title={route.menuName} placement="right">
                <Icon path={route.icon} />
              </Tooltip>
              <StyledListItemText primary={route.menuName} />
            </CustomNavLink>
          </StyledListItem>
        );
      } else if (!isMat2rix && !isMat2rixPage) {
        // Render items when isMat2rix is false
        return (
          <StyledListItem
            key={`not-mat2rix-${index}`} // Use a unique key
            className={styles.navItem}
            onClick={IsMobile ? toggleDrawerFalse : undefined}
          >
            <CustomNavLink to={route.path} exact>
              <Tooltip title={route.menuName} placement="right">
                <Icon path={route.icon} />
              </Tooltip>
              <StyledListItemText primary={route.menuName} />
            </CustomNavLink>
          </StyledListItem>
        );
      }
    });
  }, [routes, isMat2rixPage, isAdmin, isSuperAdmin]);

  return (
    <StyledDrawer
      className={styles.nav}
      variant="permanent"
      open={drawerOpen}
      classes={{
        paper: `${styles.showTopBannerNav}`, // Apply the custom class to MuiDrawer-paper
      }}
    >
      {IsMobile && (
        <DrawerHeader>
          <div className={styles.companyDropdown}>
            {!showCompanyBtn && (
              <FormControl variant="filled" className={styles.fieldWrapper}>
                <Select
                  labelId="company_select"
                  id="company"
                  displayEmpty
                  value={clientId ? clientId.toString() : ""} // Convert number to string, fallback to empty string if not selected
                  onChange={handleClient}
                  sx={{
                    "& .MuiInputBase-input": {
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      minWidth: "150px",
                      "@media screen and (max-width:768px)": {
                        minWidth: "100px", // Smaller padding for mobile
                      },
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                        overflowY: "auto",
                      },
                    },
                  }}
                >
                  {Array.isArray(clients) && clients.length > 0 ? (
                    clients.map((client) => (
                      <MenuItem key={client.id} value={client.id.toString()}>
                        {/* Convert ID to string */}
                        {client.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="0">No Clients Available</MenuItem>
                  )}
                  {isSuperAdmin && (
                    <MenuItem
                      onClick={onClickAddClientDialogOpen}
                      style={{ fontWeight: "bold" }}
                    >
                      <Icon path={mdiPlus} size={1} /> Add Client
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            )}
            {showCompanyBtn && isSuperAdmin && (
              <Button
                onClick={onClickAddClientDialogOpen}
                sx={{
                  background: "#F0F1F8",
                  border: "none",
                  color: "#000",
                  "& .MuiButton-root": {
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    minWidth: "150px",
                    "@media screen and (max-width:768px)": {
                      minWidth: "100px", // Smaller padding for mobile
                    },
                  },
                }}
              >
                <Icon path={mdiPlus} size={1} /> Add Company
              </Button>
            )}
          </div>

          <IconButton
            className={styles.drawerOpenButton}
            onClick={toggleDrawer}
          >
            <Icon path={drawerOpen ? mdiClose : mdiMenu} size={1} />
          </IconButton>
        </DrawerHeader>
      )}
      <List
        className={`${styles.navList} ${
          isMat2rixPage || (isUserDashboard && !isAdmin && !isSuperAdmin)
            ? styles.Mat2rixPage
            : ""
        }`}
      >
        {routesElement}

        {IsMobile && (
          <>
            <StyledListItem
              key={`not-mat2rix-10`} // Use a unique key
              className={styles.navItem}
              onClick={IsMobile ? toggleDrawerFalse : undefined}
            >
              <CustomNavLink to="/my-profile" exact>
                <Tooltip title="Profile Info" placement="right">
                  <Icon path={mdiAccountCircleOutline} />
                </Tooltip>
                <StyledListItemText primary="Profile Info" />
              </CustomNavLink>
            </StyledListItem>

            <StyledListItem
              key={`not-mat2rix-11`} // Use a unique key
              className={styles.navItem}
              onClick={() => {
                handleLogout(); // Invoke the function
                if (IsMobile) toggleDrawerFalse(); // Call the function if IsMobile is true
              }}
            >
              <CustomNavLink to="/login">
                <Tooltip title="Logout" placement="right">
                  <Icon path={mdiLogout} />
                </Tooltip>
                <StyledListItemText onClick={handleLogout} primary="Logout" />
              </CustomNavLink>
            </StyledListItem>
          </>
        )}
      </List>

      <AddClientDialog
        open={addClientDialogOpen}
        onClose={onClickAddClientDialogClose}
        onSuccess={handleRefresh}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={10000}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={severity}
          icon={getIcon()}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </StyledDrawer>
  );
};

export default NavigationBar;

import React, { useEffect, useState } from "react";
import styles from "./forgetPassword.module.scss";
import {
  mdiCheck,
  mdiClose,
  mdiArrowLeft,
  mdiAlertCircleOutline,
} from "@mdi/js";
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  Snackbar,
  TextField,
  Alert,
} from "@mui/material";
import Icon from "@mdi/react";
import { useNavigate } from "react-router";
import { userService } from "../../api/services";
import { Loader } from "../../components";

const ForgetPassword: React.FC = () => {
  const navigate = useNavigate();
  const [isFormValid, setIsFormValid] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error">("success");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [formDetails, setFormDetails] = useState({
    email: "",
  });
  const [fieldValidation, setFieldValidation] = useState({
    email: {
      error: false,
      message: "",
    },
  });
  const [showLoader, setShowLoader] = useState(false);
  // Close Snackbar
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // Dynamically assign custom icons based on severity
  const getIcon = () => {
    if (severity === "success") {
      return <Icon path={mdiCheck} size={1} />; // Custom success icon
    }
    return <Icon path={mdiAlertCircleOutline} size={1} />; // Custom error icon
  };

  // Handle back button click
  const handleBackClick = () => {
    navigate("/login"); // Navigate back one page
  };
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormDetails({ ...formDetails, email: event.target.value });
    setFieldValidation((prev) => ({
      ...prev,
      email: { error: false, message: "" },
    }));
    setIsDisabled(false);
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const [hasSentEmail, setHasSentEmail] = useState(false);
  const [hasResent, setHasResent] = useState(false);
  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsDisabled(true);

    // Validation for email field
    if (!formDetails.email) {
      setFieldValidation((prev) => ({
        ...prev,
        email: { error: true, message: "Email is required" },
      }));
      setIsDisabled(false);
      return;
    }

    if (!validateEmail(formDetails.email)) {
      setFieldValidation((prev) => ({
        ...prev,
        email: { error: true, message: "Invalid email format" },
      }));
      setIsDisabled(false);
      return;
    }

    try {setShowLoader(true);
      const payload = { email: formDetails.email };
      const response = await userService.sendForgotPasswordEmail(payload);

      if (response.isSuccess) {
        setSeverity("success");
        setSnackbarMessage(
          hasSentEmail
            ? "Password reset link has been re-sent to your email."
            : "Password reset link has been sent to your email."
        );
        if (hasSentEmail) {
          // If it's a resend, disable the button
          setHasResent(true);
        } else {
          // First time sending, allow resend
          setHasSentEmail(true);
        }
      } else {
        const errorMessage =
          response.errors && response.errors.length > 0
            ? response.errors[0]
            : "Failed to send password reset link.";
        setSeverity("error");
        setSnackbarMessage(errorMessage);
      }
    } catch (error: any) {
      // Fallback error handling
      const errorMessage =
        error?.response?.data?.errors?.[0] || // Handle API errors, if any
        "An unexpected error occurred. Please try again later."; // Fallback error message
      setSeverity("error");
      setSnackbarMessage(errorMessage);
    } finally {
      setOpenSnackbar(true);setShowLoader(false);
      if (!hasResent) setIsDisabled(false);
    }
  };

  return (
    <Grid container className={styles.full_height_section}>
      <Grid item sm={6} className={styles.login_left}>
        <div className={styles.login_left_content}>
          <h3> ICM </h3>
          <p>
            ICM is designed to bring your inventory count process into the 21st
            Century, empowering businesses to achieve greater accuracy and
            efficiency in managing physical inventory.
          </p>
        </div>
      </Grid>
      <Grid item sm={6} className={styles.login_right}>
        <div className={styles.login_right_content}>
          <Grid container item spacing={2}>
            <Grid item xs={12}>
              <IconButton
                onClick={handleBackClick}
                aria-label="back"
                sx={{ mr: 0.5, pl: 0 }}
              >
                <Icon path={mdiArrowLeft} size={0.8} />
              </IconButton>
              <span onClick={handleBackClick} style={{ cursor: 'pointer' }}>Back to Sign In</span>
            </Grid>
            <Grid item xs={12}>
              <h3>Forgot Password?</h3>
              <p>
                {" "}
                Please enter your email address and we will send a reset password link to your email.{" "}
              </p>
            </Grid>
            <Grid item xs={12}>
              <FormControl className="fieldWrapper" fullWidth>
                <TextField
                  id="emailAddress"
                  name="emailAddress"
                  required
                  type="email"
                  label="Email Address"
                  placeholder="Enter Your Email Address"
                  onChange={handleEmailChange}
                  value={formDetails.email}
                  autoFocus
                  error={fieldValidation.email.error}
                  helperText={fieldValidation.email.message}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button
                id="register"
                fullWidth
                variant="contained"
                disabled={isDisabled || hasResent} 
                onClick={handleSubmit}
             >
              {hasSentEmail ? "Resend" : "Send"}
              </Button>
            </Grid>
          </Grid>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={openSnackbar}
          autoHideDuration={10000}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={severity}
            icon={getIcon()}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Grid>
      {showLoader && <Loader/>}
    </Grid>
  );
};

export default ForgetPassword;

import React, { Suspense, useEffect, useState } from "react";
import styles from "./dashboard.module.scss";
import {
  mdiCheck,
  mdiChevronLeft,
  mdiChevronRight,
  mdiClose,
  mdiDotsVertical,
  mdiTrayArrowDown,
  mdiLockOutline,
  mdiAlertCircleOutline,
} from "@mdi/js";
import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  LinearProgress,
  LinearProgressProps,
  Link,
  Menu,
  MenuItem,
  Snackbar,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
  Tooltip,
} from "@mui/material";
import Icon from "@mdi/react";
import { AddInventoryDialog, UserStateAudit, UserStateCount } from "..";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { EmptyDashboard, ConfirmationDialog, Loader } from "../../components";
import { dashboardService, inventoryService } from "../../api/services";
import { IInventoryCompletionData, IRole } from "../../interfaces";
import { useNavigate } from "react-router-dom";
import { useClient } from "../../utils/ClientContext";
import { UserRoles } from "../../utils/Enum/UserRole";
import { CreateCopyInventoryDialog } from "./../../containers";

interface InventoryItem {
  inventoryId: number;
  inventoryName: string;
  inventoryNumber: number;
}

const CustomArrow = (props: any) => {
  const { className, style, onClick, icon } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      {icon}
    </div>
  );
};

const Dashboard: React.FC = () => {
  const theme = useTheme();
  const IsMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { client } = useClient();
  const clientId = client.id;
  const [loading, setLoading] = useState<boolean>(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [activeInventory, setActiveInventory] = useState<any>(null);
  const [inventoryCompletionData, setInventoryCompletionData] =
    useState<IInventoryCompletionData>();
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error">("success");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isMasterConfigured, setIsMasterConfigured] = useState(false);
  const [isInventoryConfigured, setIsInventoryConfigured] = useState(false);
  const [addInventoryDialogOpen, setAddInventoryDialogOpen] = useState(false);
  const [enableViewMyTask, setEnableViewMyTask] = useState<boolean>(false);
  const [isTagCompleted, setIsTagCompleted] = useState(true);
  const [isInventoryLocked, setIsInventoryLocked] = useState(false);
  const userId = Number(localStorage.getItem("userId"));
  const [inventoryId, setInventoryId] = useState<number | null>(null);
  const [inventoryMenuEl, setInventoryMenuEl] = useState<null | HTMLElement>(
    null
  );

  const [currentSlide, setCurrentSlide] = useState(0);
  const [subMenuInventoryEl, setSubMenuInventoryEl] =
    useState<null | HTMLElement>(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [createCopyInventoryOpen, setCreateCopyInventoryOpen] = useState(false);
  const [inventoryData, setInventoryData] = React.useState<InventoryItem[]>([]);
  const [fetchCompletionData, setfetchCompletionData] = useState(false);
  const navigate = useNavigate();

  // Dynamically assign custom icons based on severity
  const getIcon = () => {
    if (severity === "success") {
      return <Icon path={mdiCheck} size={1} />; // Custom success icon
    }
    return <Icon path={mdiAlertCircleOutline} size={1} />; // Custom error icon
  };

  //Close Snackbar
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setInventoryMenuEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setInventoryMenuEl(null);
    setSubMenuInventoryEl(null);
  };

  const handleNewSession = () => {
    setCreateCopyInventoryOpen(true);
    handleMenuClose();
  };

  const onCreateCopyInventoryClose = () => {
    setCreateCopyInventoryOpen(false);
    handleMenuClose();
  };
  const toggleLoader = (loading: boolean) => {
    setShowLoader(loading);
  };
  useEffect(() => {
    setEmptyState(false);
    setfetchCompletionData(false);
    setCurrentSlide(0);
    fetchDashBoardData();
  }, [clientId]);

  const fetchDashBoardData = async () => {
    try {
      setShowLoader(true);
        const { isMasterConfigured, isInventoryConfigured } =
            await getInventoryMasterStatus();
        if (isMasterConfigured && isInventoryConfigured) {
            await fetchActiveInventory();
            await getClientInventoriesByClient(clientId);
        } else if (isMasterConfigured) {
            await fetchRecentlyLockedInventory();
            await getClientInventoriesByClient(clientId);
        } else {
            setEmptyState(true);
        }
    } finally {
      setShowLoader(false);
    }
  };

  const getInventoryMasterStatus = async (): Promise<{
    isMasterConfigured: boolean;
    isInventoryConfigured: boolean;
  }> => {
    try {
      setLoading(true);
      const response = await dashboardService.getInventoryMasterStatusAsync(
        clientId
      );
      if (response.isSuccess) {
        setIsMasterConfigured(response.isMasterConfigured);
        setIsInventoryConfigured(response.isInventoryConfigured);
        return {
          isMasterConfigured: response.isMasterConfigured,
          isInventoryConfigured: response.isInventoryConfigured,
        };
      } else {
        setSeverity("error");
        setSnackbarMessage("Failed to fetch Inventory Status");
        setOpenSnackbar(true);
        return { isMasterConfigured: false, isInventoryConfigured: false };
      }
    } catch (error) {
      setSeverity("error");
      setSnackbarMessage("Failed to fetch Inventory Status");
      setOpenSnackbar(true);
      return { isMasterConfigured: false, isInventoryConfigured: false };
    } finally {
      setLoading(false);
    }
  };

  //Fetch Inventory Completion Data
  const fetchInventoryCompletionData = async () => {
    try {
      setLoading(true);
      const response = await dashboardService.getInventoryCompletionDataAsync(
        clientId,
        Number(localStorage.getItem("inventoryId"))
      );
      if (response.isSuccess) {
        setInventoryCompletionData(response);
        setfetchCompletionData(true);
      }
    } catch (error) {
      setSeverity("error");
      setSnackbarMessage("Failed to load Inventory Completion Data");
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const handleViewMyTask = () => {
    navigate("/user-dashboard");
  };

  // Lock Inventory
  const handleLockInventory = async () => {
    if (!activeInventory || !inventoryId) return;
    try {
      const lockInventoryRequestModel = {
        userId: userId,
        inventoryId: inventoryId,
      };
      const data = await inventoryService.lockInventoryAsync(
        lockInventoryRequestModel
      );
      setIsInventoryLocked(true);
      setSeverity("success");
      setSnackbarMessage(data.message);
      setOpenSnackbar(true);
      setTimeout(() => {
        handleMenuClose();
        handleCloseSnackbar();
      }, 5000);
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message || "Error locking inventory.";
      setSeverity("error");
      setSnackbarMessage(errorMessage);
      setOpenSnackbar(true);
      setTimeout(() => {
        handleMenuClose();
        handleCloseSnackbar();
      }, 5000);
      console.error("Error locking inventory:", errorMessage);
    }
  };

  // Fetch Recently Locked Inventory
  const fetchRecentlyLockedInventory = async () => {
    try {
      const data =
        await inventoryService.getRecentlyLockedInventorySessionAsync(clientId);
      if (
        data.isSuccess &&
        data?.inventory &&
        data?.inventory.inventoryId != 0
      ) {
        setActiveInventory(data.inventory);
        localStorage.setItem(
          "inventoryId",
          data.inventory.inventoryId.toString()
        );
        setInventoryId(data.inventory.inventoryId);
        setIsInventoryConfigured(true);
        setIsInventoryLocked(data.inventory.isLocked);
        setIsTagCompleted(data.inventory.isTagCompleted);
        await fetchInventoryCompletionData();
      } else {
        setEmptyState(true);
      }
    } catch (error) {
      setEmptyState(true);
      console.error(
        "Failed to fetch recently locked inventory session:",
        error
      );
    }
  };

  const fetchActiveInventory = async () => {
    try {
      setLoading(true);
      const data = await inventoryService.getActiveInventorySessionAsync(
        clientId
      );
      determineUserRoles();
      if (
        data.isSuccess &&
        data?.inventory &&
        data?.inventory.inventoryId != 0
      ) {
        setActiveInventory(data.inventory);
        localStorage.setItem(
          "inventoryId",
          data.inventory.inventoryId.toString()
        );
        setIsInventoryLocked(false);
        setInventoryId(data.inventory.inventoryId);
        setIsTagCompleted(data.inventory.isTagCompleted);
        await fetchInventoryCompletionData();
      } else {
        setEmptyState(true);
      }
    } catch (error) {
      setEmptyState(true);
      setSeverity("error");
      setSnackbarMessage("Failed to Inventory Name");
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const determineUserRoles = () => {
    const storedUserDetails = localStorage.getItem("UserDetails");
    if (storedUserDetails) {
      const userDetails = JSON.parse(storedUserDetails);
      if (userDetails && Array.isArray(userDetails.roles)) {
        const isCounterOrAuditor = userDetails.roles.some(
          (role: IRole) =>
            role.roleId === UserRoles.SuperAdmin ||
            role.roleId === UserRoles.Counter ||
            role.roleId === UserRoles.Auditor
        );
        setEnableViewMyTask(isCounterOrAuditor);
      }
    }
  };

  const onAddInventoryDialogOpen = () => {
    setAddInventoryDialogOpen(true);
  };

  const onAddInventoryDialogClose = () => {
    setAddInventoryDialogOpen(false);
  };

  const handleExportTagClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleExportTagClose = () => {
    setAnchorEl(null);
  };

  const CustomProgressBar = styled(LinearProgress)({
    height: "6px",
    borderRadius: "4px",
    width: "100%",
    "& .MuiLinearProgress-bar": {
      backgroundColor: "var(--primary-color)",
    },
  });

  function LinearProgressWithLabel(
    props: LinearProgressProps & { value: number | string }
  ) {
    return (
      <Box className={styles.progressBarContainer}>
        <CustomProgressBar variant="determinate" {...props} />
        <Typography
          variant="body2"
          className={styles.progressValue}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    );
  }

  const [emptyState, setEmptyState] = React.useState(false);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow:
      currentSlide === 0 ? (
        <CustomArrow
          className={styles.sliderArrowRight}
          icon={<Icon path={mdiChevronRight} size={1} />}
        />
      ) : undefined,
    prevArrow:
      currentSlide === 1 ? (
        <CustomArrow
          className={styles.sliderArrowLeft}
          icon={<Icon path={mdiChevronLeft} size={1} />}
        />
      ) : undefined,
    beforeChange: (oldIndex: number, newIndex: number) => {
      setCurrentSlide(newIndex);
    },
    responsive: [
      {
        breakpoint: 1024, // For devices with width less than 1024px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true, // Enable dots for smaller screens
        },
      },
      {
        breakpoint: 768, // For devices with width less than 768px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 480, // For devices with width less than 480px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };
  const navigateToInventory = () => {
    onCreateCopyInventoryClose();
    navigate("/inventory");
  };

  useEffect(() => {
    let initialFetchTimeout: any;
    let intervalId: any;

    if (activeInventory?.inventoryId && !activeInventory?.isLocked) {
      // Set initial timeout for the first fetch
      initialFetchTimeout = setTimeout(() => {
        setCurrentSlide(0);
      }, 60000);

      // Set periodic interval for subsequent fetches
      intervalId = setInterval(() => {
        fetchDashBoardData();
        setCurrentSlide(0);
      }, 60000);
    } else {
      // Set initial timeout for the first fetch
      initialFetchTimeout = setTimeout(() => {
        setCurrentSlide(0);
      }, 900000);

      // Set periodic interval for subsequent fetches
      intervalId = setInterval(() => {
        fetchDashBoardData();
        setCurrentSlide(0);
      }, 900000);
    }

    // Cleanup to prevent duplicate intervals
    return () => {
      clearInterval(intervalId);
      clearTimeout(initialFetchTimeout);
    };
  }, [activeInventory]);

  const handleInventoryItemClick = async (inventoryId: number) => {
    try {
      const response = await inventoryService.exportTagsToExcelAsync(
        inventoryId
      );
      if (response.isSuccess) {
        const base64Data = response.excelData;

        if (!base64Data) {
          setSeverity("error");
          setSnackbarMessage("No data received from server.");
          setOpenSnackbar(true);
        }

        const binaryString = window.atob(base64Data);

        const bytes = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }

        const blob = new Blob([bytes.buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `ICM_Tags_${inventoryId}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setSeverity("success");
        setSnackbarMessage("Excel file downloaded successfully.");
        setOpenSnackbar(true);
      } else {
        const errorMessage =
          response.errors.join(", ") || "Failed to download Excel file.";
        setSeverity("error");
        setSnackbarMessage(errorMessage);
        setOpenSnackbar(true);
      }
    } catch (error: any) {
      console.error("Error downloading Excel file:", error);
      setSeverity("error");
      setSnackbarMessage("An error occurred while downloading the Excel file.");
      setOpenSnackbar(true);
    }
  };

  const getClientInventoriesByClient = async (clientId: number) => {
    try {
      const response =
        await inventoryService.getClientInventoriesByClientIdAsync(clientId);
      if (response.isSuccess) {
        if (
          response.clientInventoriesResultViewModel &&
          response.clientInventoriesResultViewModel.length > 0
        ) {
          const clientInventoriesResultViewModel =
            response.clientInventoriesResultViewModel.map((inventory: any) => {
              return {
                inventoryId: inventory.id,
                inventoryName: inventory.name,
                inventoryNumber: inventory.inventoryNumber,
              };
            });
          setInventoryData(clientInventoriesResultViewModel);
        }
      }
    } catch (error: any) {
      console.error("Error:", error);
    }
  };

  return (
    <div className={styles.dashboard_container}>
      {!loading ? (
        emptyState ? (
          <div className={styles.empty_dashboard_container}>
                 <Suspense fallback={<div>Loading...</div>}>
              {!isMasterConfigured ? (
                <EmptyDashboard
                  title="Your inventory is empty at the moment"
                  subtitle="Please select Add Master to start adding your Masters."
                  buttonText="Add Master"
                  buttonLink="/settings/masters"
                />
              ) : !isInventoryConfigured ? (
                <EmptyDashboard
                  title="You don't have any inventory sessions active at the moment"
                  subtitle="Create your first inventory to start the session"
                  buttonText="Create an Inventory"
                  buttonFunction={onAddInventoryDialogOpen}
                />
              ) : null}
            </Suspense>
          </div>
        ) : (
          <Grid container className="content_container" spacing={2}>
            <Grid container item className={styles.top_container}>
              <Grid item sm={6} className={styles.name_container}>
                <h3 className={styles.page_title}>
                  {activeInventory ? activeInventory.name : ""}
                </h3>
                {isInventoryLocked && (
                  <IconButton edge="end" size="small">
                    <Icon path={mdiLockOutline} size={1} />
                  </IconButton>
                )}
                <IconButton onClick={handleMenuOpen}>
                  <Icon path={mdiDotsVertical} size={0.8} />
                </IconButton>
                <Menu
                  anchorEl={inventoryMenuEl}
                  open={Boolean(inventoryMenuEl)}
                  onClose={handleMenuClose}
                  keepMounted
                  className={styles.actionMenulist}
                  slotProps={{
                    paper: {
                      sx: {
                        width: "250px",
                      },
                    },
                  }}
                >
                  <Tooltip
                    title={
                      (!isTagCompleted && activeInventory != null) ||
                      isInventoryLocked
                        ? "The inventory is locked, you cannot edit this inventory."
                        : ""
                    }
                    placement="top"
                    arrow
                    disableHoverListener={!isInventoryLocked} // Show tooltip only when the menu item is disabled
                  >
                    <div>
                      <MenuItem
                        onClick={() => setConfirmDialogOpen(true)}
                        disabled={
                          (!isTagCompleted && activeInventory != null) ||
                          isInventoryLocked
                        }
                      >
                        <div className={styles.menuName}>Lock Inventory</div>
                        <Icon path={mdiChevronRight} size={0.9} />
                      </MenuItem>
                    </div>
                  </Tooltip>
                  <MenuItem
                    onClick={handleNewSession}
                    disabled={!isInventoryLocked}
                  >
                    <div className={styles.menuName}>Create a new session</div>
                    <Icon path={mdiChevronRight} size={0.9} />
                  </MenuItem>
                </Menu>
              </Grid>
              <Grid item sm={6} className={styles.btn_container}>
                {enableViewMyTask && !isInventoryLocked && (
                  <Button variant="contained" onClick={handleViewMyTask}>
                    View My Tasks
                  </Button>
                )}
                <Button
                  variant="outlined"
                  aria-controls="export-menu"
                  aria-haspopup="true"
                  onClick={handleExportTagClick}
                >
                  {!IsMobile && <>Export Tags</>}
                  <Icon
                    path={mdiTrayArrowDown}
                    size={0.8}
                    style={{ marginLeft: 5 }}
                  />
                </Button>
                <Menu
                  id="export-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleExportTagClose}
                  className={styles.actionMenulist}
                  slotProps={{
                    paper: {
                      sx: {
                        width: "300px",
                      },
                    },
                  }}
                >
                  {inventoryData.map((item) => (
                    <MenuItem
                      key={item.inventoryId}
                      onClick={() => {
                        handleInventoryItemClick(item.inventoryId);
                        handleExportTagClose();
                      }}
                    >
                      <div
                        className={styles.menuName}
                      >{`${item.inventoryName} #${item.inventoryNumber}`}</div>
                      <Icon path={mdiTrayArrowDown} size={0.7} />
                    </MenuItem>
                  ))}
                </Menu>
              </Grid>
            </Grid>
            <Grid container item>
              <Grid
                container
                item
                sm={12}
                className={styles.complition_container}
              >
                <Grid item sm={8} className={styles.main_content}>
                  <h3> Total Inventory Completion % </h3>
                  <h2>
                    {Math.floor(
                      inventoryCompletionData?.inventoryCompletionPercentage ??
                        0
                    )}
                    %
                    <span>
                      {inventoryCompletionData?.completedTags}/
                      {inventoryCompletionData?.totalTags} Tags
                    </span>
                  </h2>
                </Grid>
                <Grid item sm={4} className={styles.view_inventory_link}>
                  <Link href="/inventory">
                    View Inventory <Icon path={mdiChevronRight} size={1} />
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              className={styles.counter_container}
              spacing={2}
            >
              <Grid item sm={4} xs={12}>
                <div className={styles.counter_box}>
                  <h4>
                    Count Completion %
                    <span>
                      {inventoryCompletionData?.completedCountTags}/
                      {inventoryCompletionData?.totalTagsForCount} Tags
                    </span>
                  </h4>
                  <LinearProgressWithLabel
                    value={Number(
                      inventoryCompletionData?.countCompletionPercentage
                    )}
                  />
                </div>
              </Grid>
              <Grid item sm={4} xs={12}>
                <div className={styles.counter_box}>
                  <h4>
                    Audit Completion %
                    <span>
                      {inventoryCompletionData?.completedAuditTags}/
                      {inventoryCompletionData?.totalTagsForAudit} Tags
                    </span>
                  </h4>
                  <LinearProgressWithLabel
                    value={Number(
                      inventoryCompletionData?.auditCompletionPercentage
                    )}
                  />
                </div>
              </Grid>
              <Grid item sm={4} xs={12}>
                <div className={styles.counter_box}>
                  <h4>
                    Reconcile Completion %
                    <span>
                      {inventoryCompletionData?.completedReconcileTags}/
                      {inventoryCompletionData?.totalTagsForReconcile} Tags
                    </span>
                  </h4>
                  <LinearProgressWithLabel
                    value={Number(
                      inventoryCompletionData?.reconcileCompletionPercentage
                    )}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item container sm={12}>
                {fetchCompletionData && (
                  <Grid item sm={12} className={styles.slider_container}>
                    <Slider {...settings}>
                      <Box>
                        <h2>User Status - Count</h2>
                        <UserStateCount inventoryId={inventoryId} />
                      </Box>
                      <Box>
                        <h2>User Status - Audit</h2>
                        <UserStateAudit inventoryId={inventoryId} />
                      </Box>
                    </Slider>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        )
      ) : (
        <> </>
      )}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={10000}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={severity}
          icon={getIcon()}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Suspense
        fallback={<Loader message="Please wait while we fetch data..." />}
      >
        {addInventoryDialogOpen && (
          <AddInventoryDialog
            open={addInventoryDialogOpen}
            onClose={onAddInventoryDialogClose}
            onSuccess={navigateToInventory}
            toggleLoader={toggleLoader}
          />
        )}
        {createCopyInventoryOpen && (
          <CreateCopyInventoryDialog
            open={createCopyInventoryOpen}
            onClose={onCreateCopyInventoryClose}
            onSuccess={navigateToInventory}
            toggleLoader={toggleLoader}
          />
        )}
        {confirmDialogOpen && (
          <ConfirmationDialog
            count={null}
            title="Are you sure you want to lock this inventory?"
            message="By locking it, no one from your company will be able to edit the inventory."
            open={confirmDialogOpen}
            onClose={() => setConfirmDialogOpen(false)}
            onConfirm={() => handleLockInventory()}
          />
        )}
      </Suspense>

      {showLoader && <Loader/>}
    </div>
  );
};

export default Dashboard;

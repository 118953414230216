import React, { useCallback, useState, useEffect, Suspense } from "react";
import styles from "./locations.module.scss";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
  Alert,
} from "@mui/material";
import Icon from "@mdi/react";
import {
  mdiDotsVertical,
  mdiTrayArrowDown,
  mdiCheck,
  mdiClose,
  mdiAlertCircleOutline,
} from "@mdi/js";
import {
  ActiveSwitch,
  CustomPagination,
  Datatable,
  InactiveDialog,
  Loader,
} from "../../../../components";
import { GridColDef, useGridApiRef } from "@mui/x-data-grid";
import { locationService } from "../../../../api/services";
import { MasterSection } from "../../../../enums/MasterSection";
import { AddLocationDialog, ImportSheetDialog } from "../../../../containers";
import { DialogMessage } from "../../../../utils/Enum/DialogMessage";
import { useClient } from "../../../../utils/ClientContext";
import { IMasterExportProps } from "../../../../interfaces";
import { MasterExport } from "../../../../utils/Enum/MasterExport";

const ActionMenu: React.FC<{
  id: number;
  rowData: any;
  onEdit: (data: any) => void;
}> = ({ id, rowData, onEdit }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleEdit = () => {
    onEdit(rowData);
    handleClose();
  };

  return (
    <>
      <IconButton
        aria-controls="action-menu"
        aria-haspopup="true"
        onClick={handleClick}
        aria-label="edit"
      >
        <Icon path={mdiDotsVertical} aria-label="edit" size={1} />
      </IconButton>
      <Menu
        id={`action-menu-${id}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={styles.actionMenulist}
      >
        <MenuItem onClick={handleEdit} className={styles.actionMenuitem}>
          Edit
        </MenuItem>
      </Menu>
    </>
  );
};


const Locations: React.FC<IMasterExportProps> = ({ onExportMastersData }) => {
  const dialogMessage = DialogMessage.Dialog_Message_Inactive_Location;
  const [pageSize, setPageSize] = useState<number>(100);
  const [error, setError] = useState<string | null>(null);
  const [locations, setLocations] = useState<any[]>([]);
  const [count, setCount] = React.useState(0);
  const [emptyState, setEmptyState] = React.useState(false);
  const [inactiveDialogOpen, setInactiveDialogOpen] = useState(false);
  const [locationId, setLocationId] = useState<number | null>(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error">("success");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = React.useState(0);
  const { client } = useClient();
  const clientId = client.id;
  const userId = Number(localStorage.getItem("userId"));
  const [AddLocationDialogOpen, setAddLocationDialogOpen] =
    React.useState(false);
  const [importSheetDialogOpen, setImportSheetDialogOpen] =
    useState<boolean>(false);
    const [showLoader, setShowLoader] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const handleRowSelectionChange = (newSelection: number[]) => {
    setSelectedRows(newSelection);
  };
  const apiRef = useGridApiRef();

  //Open Location Dialog
  const onClickAddLocationDialogOpen = () => {
    setAddLocationDialogOpen(true);
  };

  //Close Location Dialog
  const onClickAddLocationDialogClose = useCallback(() => {
    setLocationId(null);
    setAddLocationDialogOpen(false);
  }, []);

  //Handle Edit
  const handleEdit = (data: any) => {
    setLocationId(data.id);
    setAddLocationDialogOpen(true);
  };

  //Handle Status Update
  const handleMakeInactive = (id: number) => {
    handleStatusChange(id, false, true);
  };

  //Handle Status Change
  const handleStatusChange = async (
    id: number,
    isActive: boolean,
    isConfirm: boolean
  ) => {
    if (isActive) {
      setShowLoader(true);
      try {
        const rowId: number[] = [id];
        const requestBody = {
          ids: rowId,
          isActive: isActive,
          modifiedBy: userId,
        };
        const response = await locationService.updateStatusAsync(requestBody);
        if (response.status === 200) {
          handleDeselectAll();
          setSnackbarMessage("Location status updated successfully.");
          setSeverity("success");
          setOpenSnackbar(true);
          setTimeout(() => {
            handleCloseSnackbar();
          }, 1000);
          handleRefresh();
        }
      } catch (error) {
        setSeverity("error");
        setSnackbarMessage("Failed to update status.");
        setOpenSnackbar(true);
        console.error("Failed to update status:", error);
      }finally {
        setShowLoader(false);
      }
    } else {
      if (isConfirm) {
        setShowLoader(true);try {
          const rowId: number[] = [id];
          const requestBody = {
            ids: rowId,
            isActive: isActive,
            modifiedBy: userId,
          };

          const response = await locationService.updateStatusAsync(requestBody);
          if (response.status === 200) {
            handleDeselectAll();
            setSnackbarMessage("Location status updated successfully.");
            setSeverity("success");
            setOpenSnackbar(true);
            setTimeout(() => {
              handleCloseSnackbar();
            }, 5000);
            handleRefresh();
          }
        } catch (error) {
          console.error("Failed to update status:", error);
          setSeverity("error");
          setSnackbarMessage("Failed to update status.");
          setOpenSnackbar(true);
        }finally {
          setShowLoader(false);
        }
      }
    }
  };

  // Dynamically assign custom icons based on severity
  const getIcon = () => {
    if (severity === "success") {
      return <Icon path={mdiCheck} size={1} />; // Custom success icon
    }
    return <Icon path={mdiAlertCircleOutline} size={1} />; // Custom error icon
  };

  //Close Snackbar
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  //Fetch Locations
  const fetchLocations = async (page: number, pageSize: number) => {
    try {
      setShowLoader(true);
      const response = await locationService.getAllLocations(clientId, {
        pageSize: pageSize,
        currentPage: page + 1,
      });
      const newLocations = response.data.locations;

      if (newLocations.length > 0) {
        setEmptyState(false);
        setLocations(newLocations);
        setPageCount(Math.ceil(response.data.totalRecords / pageSize));
        
      } else{
        setEmptyState(true);
      }
    } catch (error) {
      setEmptyState(true);
      setSeverity("error");
      setSnackbarMessage("Failed to load locations");
      setOpenSnackbar(true);
      console.error(error);
    } finally {
      setShowLoader(false);
    }
  };
  //Handle Refersh
  const handleRefresh = () => {
    setPageSize(100);
    setLocations([]);
    fetchLocations(page, pageSize);
  };

  //Handle Status Update
  const handleMarkInactive = async (selectedRows: number[]) => {
    setShowLoader(true);try {
      const payload = {
        Ids: selectedRows,
        ModifiedBy: userId,
      };
      const response = await locationService.updateStatusAsync(payload);
      handleDeselectAll();
      if (response.status === 200) {
        setSeverity("success");
        setSnackbarMessage("Location status updated successfully.");
        setOpenSnackbar(true);
        setTimeout(() => {
          handleCloseSnackbar();
        }, 5000);
        handleRefresh();
      }
    } catch (error) {
      setSeverity("error");
      setSnackbarMessage("Failed to update status.");
      setOpenSnackbar(true);
      console.error("Failed to update status", error);
    } finally {setShowLoader(false);
      setInactiveDialogOpen(false);
    }
  };

  useEffect(() => {
    setLocations([]);
    fetchLocations(page, pageSize);
  }, [page]);

  useEffect(() => {
    setPage(0);
    setPageCount(0);
    setLocations([]);
    fetchLocations(page, pageSize);
  }, [clientId]);
  
  const columns: GridColDef[] = [
    {
      field: "location",
      headerName: "Location",
      width: 150,
      minWidth: 150,
      flex: 1.5,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "area",
      headerName: "Area",
      width: 150,
      minWidth: 150,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "description",
      headerName: "Description",
      width: 200,
      minWidth: 150,
      flex: 1.5,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "status",
      headerName: "Status",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      flex: 1,
      width: 150,
      minWidth: 120,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Box display="flex" alignItems="center" width="100%" height="100%">
          {/* <ActiveSwitch isActive={params.row.isActive} /> */}
          <ActiveSwitch
            isActive={params.row.isActive}
            onConfirmInactive={() => handleMakeInactive(params.row.id)}
            message={dialogMessage}
            onChange={(event) => {
              const newActiveStatus = event.target.checked;
              handleStatusChange(params.row.id, newActiveStatus, false);
            }}
          />
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: "",
      sortable: false,
      width: 120,
      minWidth: 80,
      align: "right",
      renderCell: (params) => (
        <ActionMenu
          id={params.row.id as number}
          rowData={params.row}
          onEdit={handleEdit}
        />
      ),
    },
  ];

  const rows = locations.map((location) => ({
    id: location.locationId,
    location: location.locationName,
    description: location.description,
    clientId: location.clientId,
    createdBy: location.createdBy,
    createdDate: location.createdDate,
    isActive: location.isActive,
    isDeleted: location.isDeleted,
    isExclude: location.isExclude,
    area: location.area.areaName ? location.area.areaName : "N/A",
  }));

  const handleDeselectAll = () => {
    // Deselect all rows
    if (apiRef.current) {
      apiRef.current.setRowSelectionModel([]);
    };
  };
 // Handles the page change event
 const handlePageChange = (newPage: number) => {
  setPage(newPage);
};
const handleFirstPage = () => {
  setPage(0);
};

const handleLastPage = () => {
  setPage(pageCount - 1);
};

const handleNextPage = () => {
  if (page < pageCount - 1) {
    setPage((prev) => prev + 1);
  }
};

const handlePreviousPage = () => {
  if (page > 0) {
    setPage((prev) => prev - 1);
  }
};
  return (
    <div className="comman_wrapper">
      {emptyState ? (
        <Grid container item className={styles.dashboard_container_empty}>
          <Grid item xs={12} className={styles.dashboard_empty_content}>
            <img
              src={process.env.PUBLIC_URL + "/assets/blank_screen_img.svg"}
              alt="Blank Screen Img"
            />
            <h3> Your Locations are empty at the moment </h3>
            <h5>
              {" "}
              Please import an excel sheet to start adding your inventory.{" "}
            </h5>
            <Grid item className={styles.add_master_btn}>
              <Button
                variant="contained"
                onClick={() => {  setImportSheetDialogOpen(true);
                }}
              >
                Import Sheet
              </Button>
              <Button
                variant="outlined"
                id="addLocation"
                onClick={onClickAddLocationDialogOpen}
              >
                Add Location
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container item className={styles.datagrid_wrapper}>
          {selectedRows.length > 0 ? (
            <Grid item xs={12} className={styles.datagrid_top_btns}>
              <Button
                id="openInactiveDialog"
                variant="outlined"
                onClick={() => setInactiveDialogOpen(true)}
              >
                Make Inactive ({selectedRows.length})
              </Button>
              <Button
                id="deselectAll"
                variant="outlined"
                onClick={handleDeselectAll}
              >
                Unselect All ({selectedRows.length})
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} className={styles.datagrid_top_btns}>
              <Button
                variant="outlined"
                onClick={() => setImportSheetDialogOpen(true)}
              >
                Import Sheet
              </Button>
              <Button variant="outlined" onClick={onClickAddLocationDialogOpen}>
                Add Location
              </Button>
              <Button variant="outlined" onClick={()=>onExportMastersData(MasterExport.Locations)}>
                <Icon path={mdiTrayArrowDown} size={1}></Icon>
              </Button>
            </Grid>
          )}
          <Grid item xs={12} className={styles.datagrid_container}>
            <Datatable
              rows={rows}
              columns={columns}
              rowCount={count}
              loading={showLoader}
              pagination
              paginationMode="server"
              onPageChange={handlePageChange}
              disableSelectionOnClick={false}
              error={error}
              checkboxSelection={true}
              onSelectionModelChange={handleRowSelectionChange}
              apiRef={apiRef}
            />
            <CustomPagination
                    page={page}
                    pageCount={pageCount || 1}
                    handleFirstPage={handleFirstPage}
                    handleLastPage={handleLastPage}
                    handleNextPage={handleNextPage}
                    handlePreviousPage={handlePreviousPage}
                  />
    
          </Grid>
        </Grid>
      )} 
      
    
      {/* Lazy loaded AddLocationDialog with Suspense */}
      <Suspense fallback={<div>Loading...</div>}>
        {AddLocationDialogOpen && (
          <AddLocationDialog
            open={AddLocationDialogOpen}
            onClose={onClickAddLocationDialogClose}
            locationId={locationId}
            onSuccess={handleRefresh}
          />
        )}
        {importSheetDialogOpen && (
          <ImportSheetDialog
            open={importSheetDialogOpen}
            onClose={() => {
              setImportSheetDialogOpen(false);
            }}
            section={MasterSection.Location}
            onSuccess={handleRefresh}
          />
        )}
      </Suspense>
      <InactiveDialog
        open={inactiveDialogOpen}
        onClose={() => setInactiveDialogOpen(false)}
        onConfirm={() => handleMarkInactive(selectedRows)}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={10000}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={severity}
          icon={getIcon()}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {showLoader && <Loader/>}
    </div>
  );
};

export default Locations;

import React from "react";

export { default as Header } from "./Header/Header";
export { default as NavigationBar } from "./Navigation/NavigationBar";
export { default as BreadcrumbsComponent } from "./Breadcrumbs/Breadcrumbs";
export { default as Datatable } from "./Datatable/Datatable";
export { default as ActiveSwitch } from "./ActiveSwitch/ActiveSwitch";
export { default as DialogStyle } from "./DialogStyles/DialogStyle";
export { default as InactiveDialog } from "./InactiveDialog/InactiveDialog";
export const FilterDropdown = React.lazy(
  () => import("./FilterDropdown/FilterDropdown")
);
export const EmptyDashboard = React.lazy(
  () => import("./EmptyDashboard/EmptyDashboard")
);
export const CustomPagination = React.lazy(
  () => import("./CustomPagination/CustomPagination")
);
export const ConfirmationDialog = React.lazy(
  () => import("./ConfirmationDialog/ConfirmationDialog")
);

export const InfiniteScroll = React.lazy(
  () => import("./InfiniteScroll/InfiniteScroll")
);

export { default as SearchInput } from "./SearchInput/SearchInput";
export { default as Loader } from "./Loader/Loader";

import React, { Suspense, useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Snackbar,
  Alert,
  Tooltip,
} from "@mui/material";
import styles from "./inventory.module.scss";
import {
  mdiChevronRight,
  mdiDotsVertical,
  mdiCheck,
  mdiClose,
  mdiLockOutline,
  mdiAlertCircleOutline,
} from "@mdi/js";
import Icon from "@mdi/react";
import { inventoryService } from "../../api/services";
import {
  CreateCopyInventoryDialog,
  InventoryDetails,
} from "./../../containers";
import { EmptyDashboard, ConfirmationDialog, Loader } from "../../components";
import { useClient } from "../../utils/ClientContext";

const Inventory: React.FC = () => {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error">("success");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [activeInventory, setActiveInventory] = useState<any>(null);
  const [recentlyLockedInventory, setRecentlyLockedInventory] =
    useState<any>(null);
  const [inventoryId, setInventoryId] = useState<number | null>(null);
  const [emptyState, setEmptyState] = useState(true);
  const [isTagCompleted, setIsTagCompleted] = useState(true);
  const [isLocked, setIsLocked] = useState(false);
  const userId = Number(localStorage.getItem("userId"));
  const [inventoryMenuEl, setInventoryMenuEl] = useState<null | HTMLElement>(
    null
  );
  const [subMenuInventoryEl, setSubMenuInventoryEl] =
    useState<null | HTMLElement>(null);
  const [createCopyInventoryOpen, setCreateCopyInventoryOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [hasInventorySession, setHasInventorySession] = React.useState(false);
  const [userName, setUserName] = useState("");
  const { client } = useClient();
  const clientId = client?.id;
  const [reloadInventoryData, setReloadInventoryData] = useState<Boolean>(false);
  const [showLoader, setShowLoader] = useState(false);
  // Dynamically assign custom icons based on severity
  const getIcon = () => (
    <Icon
      path={severity === "success" ? mdiCheck : mdiAlertCircleOutline}
      size={1}
    />
  );

  // Close Snackbar
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleDialogSuccess = (isSuccess: boolean, message: string) => {
    if (isSuccess) {
      setEmptyState(true);
      setSeverity("success");
      setSnackbarMessage(message);
      setOpenSnackbar(true);
      setTimeout(() => {
        setOpenSnackbar(false);
      }, 3000);
      setReloadInventoryData((prev) => !prev);
    }
    setCreateCopyInventoryOpen(false);
  };

  // Fetch Active Inventory
  const fetchActiveInventory = async () => {
    try {setShowLoader(true);
      const data = await inventoryService.getActiveInventorySessionAsync(
        clientId
      );
      if (data?.inventory && data?.inventory.inventoryId != 0) {
        setActiveInventory(data.inventory);
        localStorage.setItem("inventoryId", data.inventory.inventoryId);
        setInventoryId(data.inventory.inventoryId);
        setIsTagCompleted(data.inventory.isTagCompleted);
        setIsLocked(false);
        setEmptyState(false);
        setHasInventorySession(true);
      } else {
        fetchRecentlyLockedInventory();
      }
    } catch (error) {
      console.error("Failed to fetch active inventory session:", error);
      setEmptyState(true);
    }finally {
      setShowLoader(false);
    }
  };

  // Fetch Recently Locked Inventory
  const fetchRecentlyLockedInventory = async () => {
    try {setShowLoader(true);
      const data =
        await inventoryService.getRecentlyLockedInventorySessionAsync(clientId);
      if (data?.inventory) {
        setActiveInventory(data.inventory);
        localStorage.setItem("inventoryId", data.inventory.inventoryId);
        setIsLocked(data.inventory.isLocked);
        setInventoryId(data.inventory.inventoryId);
        setIsTagCompleted(data.inventory.isTagCompleted);
        setHasInventorySession(true);
        setEmptyState(false);
      } else {
        setHasInventorySession(false);
        setEmptyState(true);
      }
    } catch (error) {
      setEmptyState(true);
      console.error(
        "Failed to fetch recently locked inventory session:",
        error
      );
    }finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    if (clientId) {
      setEmptyState(true);
      fetchActiveInventory();
    }
    const storedUserDetails = localStorage.getItem("UserDetails");
    if (storedUserDetails) {
      const userDetails = JSON.parse(storedUserDetails);
      if (userDetails && Array.isArray(userDetails.roles)) {
        setUserName(userDetails.firstName + " " + userDetails.lastName);
      }
    }
  }, [clientId, reloadInventoryData]);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setInventoryMenuEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setInventoryMenuEl(null);
    setSubMenuInventoryEl(null);
  };

  const handleNewSession = () => {
    setCreateCopyInventoryOpen(true);
    handleMenuClose();
  };
  const toggleLoader = (loading: boolean) => {
    setShowLoader(loading);
  };
  const onCreateCopyInventoryClose = () => {
    setCreateCopyInventoryOpen(false);
    handleMenuClose();
  };

  // Lock Inventory
  const handleLockInventory = async () => {
    if (!activeInventory || !inventoryId) return;
    try {setShowLoader(true);
      const lockInventoryRequestModel = {
        UserId: userId,
        InventoryId: inventoryId,
      };
      const data = await inventoryService.lockInventoryAsync(
        lockInventoryRequestModel
      );
      handleMenuClose();
      setIsLocked(true);
      setSeverity("success");
      setSnackbarMessage(data.message);
      setOpenSnackbar(true);
      setTimeout(() => {
        handleCloseSnackbar();
      }, 5000);
      await fetchRecentlyLockedInventory();
    } catch (error: any) {
      setConfirmDialogOpen(false);
      const errorMessage =
        error.response?.data?.message || "Error locking inventory.";
      handleMenuClose();
      setSeverity("error");
      setSnackbarMessage(errorMessage);
      setOpenSnackbar(true);
      setTimeout(() => {
        handleCloseSnackbar();
      }, 5000);
      console.error("Error locking inventory:", errorMessage);
    }finally {
      setShowLoader(false);
    }
  };

  return (
    <div className="content_container">
      {hasInventorySession ? (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h3 className={styles.page_title}>
                {activeInventory ? activeInventory.name : ""}
                {isLocked && (
                  <IconButton edge="end" size="small">
                    <Icon path={mdiLockOutline} size={1} />
                  </IconButton>
                )}
                <IconButton onClick={handleMenuOpen}>
                  <Icon path={mdiDotsVertical} size={1} />
                </IconButton>
                <Menu
                  anchorEl={inventoryMenuEl}
                  open={Boolean(inventoryMenuEl)}
                  onClose={handleMenuClose}
                  keepMounted
                  className={styles.inventoryDropMenu}
                  slotProps={{
                    paper: {
                      sx: {
                        width: "250px",
                      },
                    },
                  }}
                >
                  <Tooltip
                    title={
                      (!isTagCompleted && activeInventory != null) || isLocked
                        ? "The inventory is locked, you cannot edit this inventory."
                        : ""
                    }
                    placement="top"
                    arrow
                    disableHoverListener={!isLocked} // Show tooltip only when the menu item is disabled
                  >
                    <div>
                      <MenuItem
                        onClick={() => setConfirmDialogOpen(true)}
                        disabled={
                          (!isTagCompleted && activeInventory != null) ||
                          isLocked
                        }
                      >
                        Lock Inventory{" "}
                        <Icon path={mdiChevronRight} size={0.9} />
                      </MenuItem>
                    </div>
                  </Tooltip>
                  <MenuItem onClick={handleNewSession} disabled={!isLocked}>
                    Create a new session{" "}
                    <Icon path={mdiChevronRight} size={0.9} />
                  </MenuItem>
                </Menu>
              </h3>
            </Grid>
          </Grid>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={openSnackbar}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
          >
            <Alert
              onClose={handleCloseSnackbar}
              severity={severity}
              icon={getIcon()}
              sx={{ width: "100%" }}
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
          {emptyState ? (
            <EmptyDashboard
              title="No Tag Added"
              subtitle="Currently, no tag is added at the moment."
            />
          ) : (
            <InventoryDetails
              updateEmptyState={() => {
                setEmptyState(false);
              }}
              recentlyLockedInventory={recentlyLockedInventory}
              isLocked={isLocked}
            />
          )}
          <Suspense fallback={<div>Loading...</div>}>
            <CreateCopyInventoryDialog
              open={createCopyInventoryOpen}
              onClose={onCreateCopyInventoryClose}
              onSuccess={handleDialogSuccess}
              toggleLoader={toggleLoader}
            />
            <ConfirmationDialog
              count={null}
              title="Are you sure you want to lock this inventory?"
              message="By locking it, no one from your company will be able to edit the inventory."
              open={confirmDialogOpen}
              onClose={() => setConfirmDialogOpen(false)}
              onConfirm={handleLockInventory}
            />
          </Suspense>
        </>
      ) : (
        <>
          <h3 className={styles.page_title}>Welcome {userName}</h3>
          <p>At the moment, <strong>No Inventory Session</strong> is active.</p>
        </>
      )} {showLoader && <Loader/>}
    </div>
  );
};

export default Inventory;

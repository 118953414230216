import React, { useEffect, useState } from "react";
import styles from "./login.module.scss";
import {
  mdiEyeOffOutline,
  mdiEyeOutline,
  mdiCheck,
  mdiClose,
  mdiAlertCircleOutline,
} from "@mdi/js";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Snackbar,
  TextField,
  Alert,
} from "@mui/material";
import Icon from "@mdi/react";
import { ILogin } from "../../interfaces";
import { loginService } from "../../api/services";
import { useNavigate } from "react-router";
import { useAuth } from "../../utils";
import { Loader } from "../../components";

const Login: React.FC = () => {
  const navigate = useNavigate();
  const [isFormValid, setIsFormValid] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error">("success");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isLoginClicked, setIsLoginClicked] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const { login } = useAuth();

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const [formValues, setFormValues] = useState<ILogin>({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    emailAddress: false,
    password: false,
  });

  // Email validation function
  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const trimmedEmail = email.trim();
    if (!emailRegex.test(trimmedEmail)) {
      return false;
    }
    if (!trimmedEmail.includes("@")) {
      return false;
    }
    const domain = trimmedEmail.split("@")[1];
    if (!domain || !domain.includes(".")) {
      return false;
    }
    const domainParts = domain.split(".");
    return domainParts.length === 2;
  };

  // Change event for input fields
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    // Set error states based on field
    setErrors((prevErrors) => ({
      ...prevErrors,
      emailAddress:
        name === "email" ? !validateEmail(value) : prevErrors.emailAddress,
      password: name === "password" ? value === "" : prevErrors.password,
    }));
  };

  // Validate Form
  const validateForm = () => {
    const newErrors = {
      emailAddress: !validateEmail(formValues.email),
      password: formValues.password === "",
    };
    setErrors(newErrors);
    return !newErrors.emailAddress && !newErrors.password;
  };

  const handleRememberMeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRememberMe(event.target.checked);
  };

  // Close Snackbar
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // Dynamically assign custom icons based on severity
  const getIcon = () => {
    if (severity === "success") {
      return <Icon path={mdiCheck} size={1} />; // Custom success icon
    }
    return <Icon path={mdiAlertCircleOutline} size={1} />; // Custom error icon
  };

  // Handle Login
  const handleLogin = async () => {
    const isValid = validateForm();
    if (isValid) {
      setIsLoginClicked(true);
      setShowLoader(true);
      try {
        const response = await loginService.login(formValues);
        if (response.data.isSuccess) {
          localStorage.setItem("userId", response.data.userId);
          localStorage.setItem("token", response.data.token);
          if (rememberMe) {
            localStorage.setItem("rememberMe", rememberMe.toString());
          }
          setIsLoginClicked(true);
          setSnackbarMessage("Logged in successfully.");
          setSeverity("success");
          setOpenSnackbar(true);
          login(response.data.token);
          navigate("/");
        } else {
          setIsLoginClicked(false);
          setSnackbarMessage("Login failed. Please check your credentials.");
          setSeverity("error");
          setOpenSnackbar(true);
          setTimeout(() => {
            handleCloseSnackbar();
          }, 1000);
        }
      } catch (error: any) {
        setErrors(error.response.data.errors.join(", "));
        var errorMessage = error.response.data.errors.join(", ");
        setIsLoginClicked(false);
        setSnackbarMessage(errorMessage);
        setSeverity("error");
        setOpenSnackbar(true);
        setTimeout(() => {
          handleCloseSnackbar();
        }, 10000);
      }finally {
        setShowLoader(false);
      }
    }
  };

  const handleForgetPassword = () => {
    navigate("/forget-password");
  };
  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };
  useEffect(() => {
    const isValid =
      formValues.email !== "" &&
      formValues.password !== "" &&
      !errors.emailAddress &&
      !errors.password;
    setIsFormValid(isValid);
  }, [formValues, errors]);

  return (
    <Grid container className={styles.full_height_section}>
      <Grid item sm={6} className={styles.login_left}>
        <div className={styles.login_left_content}>
          <h3> ICM </h3>
          <p>
            ICM is designed to bring your inventory count process into the 21st
            Century, empowering businesses to achieve greater accuracy and
            efficiency in managing physical inventory.
          </p>
        </div>
      </Grid>
      <Grid
        item
        sm={6}
        className={styles.login_right}
        onKeyDown={handleKeyPress} // Listen for key press events
      >
        <div className={styles.login_right_content}>
          <h3> Sign in to your Account </h3>
          <FormControl className={styles.fieldWrapper} fullWidth>
            <TextField
              id="email"
              name="email"
              type="email"
              value={formValues.email}
              label="Email Address"
              required
              placeholder="Enter Your Email Address"
              onChange={handleChange}
              error={errors.emailAddress}
              helperText={
                errors.emailAddress ? "Please enter correct email address" : ""
              }
            />
          </FormControl>
          <FormControl className={styles.fieldWrapper} fullWidth>
            <TextField
              id="password"
              name="password"
              type={showPassword ? "text" : "password"}
              value={formValues.password}
              label="Password"
              required
              placeholder="Enter Your Password"
              onChange={handleChange}
              InputProps={{
                endAdornment: formValues.password && (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <Icon path={mdiEyeOutline} size={0.7} />
                      ) : (
                        <Icon path={mdiEyeOffOutline} size={0.7} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={errors.password}
              helperText={errors.password ? "Password is required" : ""}
            />
          </FormControl>
          <FormControl
            className={`${styles.fieldWrapper} ${styles.forgetPasswordContainer}`}
            fullWidth
          >
            <Link
              onClick={handleForgetPassword}
              className={styles.forgetPassword}
            >
              Forgot password?
            </Link>
          </FormControl>
          <FormControl className={styles.fieldWrapper} fullWidth>
            <FormControlLabel
              control={
                <Checkbox
                  checked={rememberMe}
                  onChange={handleRememberMeChange}
                />
              }
              label="Remember Me"
            />
          </FormControl>
          <Button
            id="login"
            fullWidth
            variant="contained"
            disabled={!isFormValid || isLoginClicked}
            onClick={handleLogin}
          >
            Login
          </Button>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={openSnackbar}
          autoHideDuration={10000}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={severity}
            icon={getIcon()}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Grid>
      {showLoader && <Loader/>}
    </Grid>
  );
};

export default Login;

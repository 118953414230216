import React, { useState, useEffect, Suspense, startTransition } from "react";
import styles from "./reconciliationTagTile.module.scss";
import {
  Grid,
  FormControl,
  TextField,
  InputAdornment,
  IconButton,
  Autocomplete,
  Button,
  FormControlLabel,
  Checkbox,
  Snackbar,
  Alert,
  Tooltip,
} from "@mui/material";
import Icon from "@mdi/react";
import { mdiCalculator, mdiChevronDown } from "@mdi/js";
import { Calculator } from "../..";
import { ConfirmationDialog, Loader } from "../../../components";
import TagService from "../../../api/services/TagService";
import { IReconciliationTagTileResponse } from "../../../interfaces/IReconciliationTagTileResponse";
import { useLocation } from "react-router-dom";
import { IUpdateReconcileDetailsRequest } from "../../../interfaces";

enum ReconciliationTypes {
  Counter,
  Auditer,
}

const ReconciliationTagTile: React.FC = () => {
  const location = useLocation();
  const id = new URLSearchParams(location.search).get("tagid");
  const isLocked = new URLSearchParams(location.search).get("isLocked");
  const isInventoryLocked = isLocked === "true";
  const [openCalculator, setOpenCalculator] = useState(false);
  const [ConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [countValidation, setCountValidation] = useState(false);

  // Create a state variable for the input value
  const [formValues, setFormValues] = useState({
    count: "",
    uom: "",
  });

  const [errors, setErrors] = useState({
    count: false,
    uom: false,
  });

  const [tagDetails, setTagDetails] =
    useState<IReconciliationTagTileResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [tagDetailsError, setTagDetailsError] = useState<string | null>(null);
  const [uoms, setUoms] = useState<{ id: number; name: string }[]>([]);
  const [finalType, setFinalType] = useState<ReconciliationTypes | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );

  useEffect(() => {
    const fetchTagDetails = async () => {
      if (id) {
        setLoading(true);
        setTagDetailsError(null);
        try {
          const response = await TagService.getReconciliationTagAsync(+id);

          setTagDetails(response.data.reconciliationTag);
          if (response.data.reconciliationTag.isReconciliation) {
            const uomResponse = await TagService.getItemUOMAsync(
              response.data.reconciliationTag.itemId
            );

            const fetchedUoms = uomResponse.data.unitOfMeasures.map(
              (uom: any) => ({
                id: uom.id,
                name: uom.name,
              })
            );

            setUoms(fetchedUoms);

            // Select default UOM if only one is available
            if (fetchedUoms.length === 1 && response.data.reconciliationTag.reconcileUOM === "") {
              setFormValues((prevValues) => ({
                ...prevValues,
                uom: fetchedUoms[0].name,
                secondaryUomId: 0,
              }));
            }

            if (
              response.data.reconciliationTag.reconcileUOM !== "" &&
              response.data.reconciliationTag.reconcileQty !== null
            ) {
              setFormValues((prevValues) => ({
                ...prevValues,
                count: response.data.reconciliationTag.reconcileQty,
                uom: response.data.reconciliationTag.reconcileUOM,
              }));
            }
          }
        } catch (err: any) {
          setTagDetailsError(
            err.response?.data?.errors?.[0] ||
              "Failed to fetch reconciliation tag details."
          );
        } finally {
          setLoading(false);
        }
      }
    };

    fetchTagDetails();
  }, []);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Create an onChange handler
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCountValidation(false);
    const { name, value } = event.target;
    startTransition(() => {
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: value === "",
      }));
    });
  };

  // Inside your AddTagDialog component
  const handleCalculatorValue = (value: string) => {
    startTransition(() => {
      const regex = /^\d{0,8}(\.\d{0,2})?$/;
      if (
        (regex.test(value) || value === "") &&
        parseFloat(value || "0") <= 99999999.99
      ) {
        setFormValues((prevValues) => ({
          ...prevValues,
          count: value,
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          count: false,
        }));
        setIsFormValid(true);
      } else {
        setFormValues((prevValues) => ({
          ...prevValues,
          count: "",
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          count: true,
        }));
        setCountValidation(true);
        setIsFormValid(false);
      }
      setOpenCalculator(false); // Optionally close the dialog after receiving the value
    });
  };

  const onClickUpdateTagSubmit = () => {
    if (uoms.length > 0) {
      const reconcileCount: number = +formValues.count;
      const uOMId: number = uoms.find((x) => x.name == formValues.uom)?.id ?? 0;
      updateReconcileDetails(reconcileCount, uOMId);
    }
  };

  const updateReconcileDetails = async (
    reconcileCount: number,
    uOMId: number
  ) => {
    if (tagDetails != null) {
      const request: IUpdateReconcileDetailsRequest = {
        tagId: tagDetails.tagId,
        reconcileQty: reconcileCount,
        reconcilerUserId: Number(localStorage.getItem("userId")) || 0,
        reconcileUOMId: uOMId,
      };
      try {
        const response = await TagService.updateReconcileDetailsRequest(
          request
        );
        if (response.data && response.data.isSuccess) {
          setSnackbarMessage("Updated successfully!");
          setSnackbarSeverity("success");
        } else {
          setSnackbarMessage("Failed to update reconciliation tag details.");
          setSnackbarSeverity("error");
        }
      } catch (err: any) {
        setSnackbarMessage("Failed to update reconciliation tag details.");
        setSnackbarSeverity("error");
      } finally {
        setSnackbarOpen(true); // Show the snackbar
      }
    }
  };

  const handleCalculatorOpen = () => {
    startTransition(() => setOpenCalculator(true));
  };

  const handleCalculatorClose = () => {
    startTransition(() => setOpenCalculator(false));
  };

  const handleFinalCount = (value: ReconciliationTypes) => {
    setFinalType(value);
    startTransition(() => setConfirmDialogOpen(true));
  };

  if (loading) return <> <Loader/></>;
  if (tagDetailsError) return <div>Error: {tagDetailsError}</div>;

  if (!tagDetails) {
    return <div>No details found for the specified tag ID.</div>;
  }

  return (
    <div className={`${styles.tag_content_container} content_container`}>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Grid item container xs={12} className={styles.tagContainerInner}>
        <Grid item container xs={12} className={styles.tagContainerTop}>
          <Grid item container className={styles.tagName} sm={12}>
            <h3>
              <b>Tag ID</b> | #
              {tagDetails.tagNumber?.toString().padStart(2, "0")}
            </h3>
            <ul>
              <li>
                <span className={styles.labelName}> Area Name </span>
                {tagDetails.areaName}
              </li>
              <li>
                <span className={styles.labelName}> Location Name </span>
                {tagDetails.locationName}
              </li>
            </ul>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          className={`${styles.tagContainerBottom} ${styles.listContainer}`}
        >
          <ul>
            <li>
              <span className={styles.labelName}> Item ID </span>
              {tagDetails.itemName}
            </li>
            <li>
              <span className={styles.labelName}> Description </span>
              <Tooltip title={tagDetails.description} placement="bottom" arrow>
                <span className={styles.tooltip_div}>
                  {tagDetails.description}
                </span>
              </Tooltip>
            </li>
            <li>
              <span className={styles.labelName}> UOMs </span>
              {tagDetails.secondaryUOM && tagDetails.secondaryUOM !== ""
                ? tagDetails.secondaryUOM
                : tagDetails.primaryUOM}
            </li>
            <li>
              <span className={styles.labelName}> Cost </span>
              {tagDetails.cost === null ? 0 : tagDetails.cost}
            </li>
            <li>
              <span className={styles.labelName}> UOM </span>
              {tagDetails.primaryUOM}
            </li>
          </ul>
        </Grid>
      </Grid>

      <Grid item container xs={12} className={styles.tagContainerInner}>
        <Grid item xs={12} className={styles.tagContainer_heading}>
          <h4> Count Details </h4>
        </Grid>
        <Grid item xs={12} className={styles.tagContainer_details}>
          <ul>
            <li>
              Count Made
              <b>
                {tagDetails.countMade === null ? "-" : tagDetails.countMade}
              </b>
            </li>
            <li>
              UOM
              <b>{tagDetails.countUOM === null ? "-" : tagDetails.countUOM}</b>
            </li>
            <li>
              Counter
              <b>
                {tagDetails.countedBy === null ? "-" : tagDetails.countedBy}
              </b>
            </li>
          </ul>
          {tagDetails.isReconciliation && !isInventoryLocked && (
            <Button
              variant="outlined"
              onClick={() => handleFinalCount(ReconciliationTypes.Counter)}
            >
              Use this as final count
            </Button>
          )}
        </Grid>
      </Grid>

      {tagDetails.showAudit && (
        <Grid item container xs={12} className={styles.tagContainerInner}>
          <Grid item xs={12} className={styles.tagContainer_heading}>
            <h4> Audit Details </h4>
          </Grid>
          <Grid item xs={12} className={styles.tagContainer_details}>
            <ul>
              <li>
                Audit Made
                <b>
                  {tagDetails.auditMade === null ? "-" : tagDetails.auditMade}
                </b>
              </li>
              <li>
                UOM
                <b>
                  {tagDetails.auditUOM === null || tagDetails.auditUOM === ""
                    ? "-"
                    : tagDetails.auditUOM}
                </b>
              </li>
              <li>
                Auditor
                <b>
                  {tagDetails.auditedBy === null ? "-" : tagDetails.auditedBy}
                </b>
              </li>
            </ul>
            {tagDetails.isReconciliation && !isInventoryLocked && (
              <Button
                variant="outlined"
                onClick={() => handleFinalCount(ReconciliationTypes.Auditer)}
              >
                use this as final count
              </Button>
            )}
          </Grid>
        </Grid>
      )}
      {tagDetails.isReconciliation && (
        <Grid item container xs={12} className={styles.reconciliationTagBox}>
          <Grid
            item
            container
            xs={12}
            className={styles.reconciliationTagBox_heading}
          >
            <p>
              Since the quantities don't match, please input a different
              quantity.
            </p>
          </Grid>
          <Grid
            item
            container
            xs={12}
            className={styles.reconciliationTagBox_form}
          >
            <Grid
              item
              container
              className={styles.fieldContainer}
              sm={8}
              xs={12}
            >
              <Grid item sm={4} xs={5.5}>
                <FormControl className="fieldWrapper" fullWidth>
                  <TextField
                    fullWidth
                    name="count"
                    id="count"
                    required
                    value={formValues.count}
                    label="Enter the new quantity"
                    placeholder="Add quantity"
                    className={styles.whiteTextBox}
                    inputProps={{
                      inputMode: "decimal",
                      pattern: "[0-9]*",
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      const regex = /^\d{0,8}(\.\d{0,2})?$/;
                      if (
                        (regex.test(value) || value === "") &&
                        parseFloat(value || "0") <= 99999999.99
                      ) {
                        if (value !== "" && formValues.uom !== "") {
                          setIsFormValid(true);
                        } else {
                          setIsFormValid(false);
                        }
                        handleChange(e as React.ChangeEvent<HTMLInputElement>);
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="open calculator"
                            onClick={handleCalculatorOpen}
                            disabled={isInventoryLocked}
                          >
                            <Icon path={mdiCalculator} size={0.8} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    disabled={isInventoryLocked}
                    error={errors.count}
                    helperText={
                      errors.count
                        ? countValidation
                          ? "Count should be greater than 0 and less than or equal to 99999999.99"
                          : "Count is required"
                        : ""
                    }
                    sx={{
                      "& .MuiInputBase-root": {
                        background: "#fff",
                      },
                      "& .MuiInputBase-input": {
                        background: "#fff",
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={4} xs={5.5}>
                <FormControl fullWidth>
                  <Autocomplete
                    id="uom"
                    options={uoms}
                    getOptionLabel={(option) =>
                      typeof option === "string" ? option : option.name || ""
                    }
                    value={
                      uoms.find((uom) => uom.name === formValues.uom) || null
                    }
                    onChange={(event, newValue) => {
                      setFormValues((prevValues) => {
                        if (typeof newValue === "string") {
                          return {
                            ...prevValues,
                            uom: newValue,
                            secondaryUomId: 0,
                          };
                        } else if (newValue) {
                          return {
                            ...prevValues,
                            uom: newValue.name,
                            secondaryUomId: 0,
                          };
                        } else {
                          return {
                            ...prevValues,
                            uom: "",
                            secondaryUomId: 0,
                          };
                        }
                      });

                      const isValidUom =
                        (newValue && typeof newValue === "string") ||
                        (newValue?.name &&
                          uoms.some((uom) => uom.name === newValue?.name));

                      // Set form validity if UOM is valid and count is provided
                      setIsFormValid(Boolean(formValues.count && isValidUom));
                    }}
                    disabled={isInventoryLocked}
                    onInputChange={(event, newInputValue) => {
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        uom: newInputValue || "",
                        secondaryUomId: 0,
                      }));

                      // Check if the entered UOM is valid based on the uoms list
                      const isValidUom = uoms.some(
                        (uom) => uom.name === newInputValue
                      );
                      setIsFormValid(
                        Boolean(newInputValue && formValues.count && isValidUom)
                      );
                    }}
                    popupIcon={<Icon path={mdiChevronDown} size={1} />}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label="UOM"
                        name="uom"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "Select UOM",
                        }}
                        error={Boolean(errors.uom)}
                        helperText={errors.uom ? "UOM is required" : ""}
                        disabled={isInventoryLocked}
                      />
                    )}
                    sx={{
                      "& .MuiInputBase-root": {
                        background: "#fff !important",
                      },
                      "& .MuiInputBase-input": {
                        background: "#fff !important",
                      },
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container className={styles.btnContainer} sm={4} xs={12}>
              <Button
                variant="contained"
                sx={{ padding: "20px 50px" }}
                onClick={onClickUpdateTagSubmit}
                disabled={!isFormValid || isInventoryLocked}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}

      {!tagDetails.isReconciliation &&
        tagDetails.auditMade !== null &&
        tagDetails.countMade !== null && (
          <Alert icon={false} severity="info">
            Both Count and Audit numbers match! Good Job!
          </Alert>
        )}

      <Suspense fallback={<div>Loading...</div>}>
        <ConfirmationDialog
          count={
            finalType === ReconciliationTypes.Counter
              ? tagDetails.countMade
              : finalType === ReconciliationTypes.Auditer
              ? tagDetails.auditMade
              : 0
          }
          title="Make this as a Final Count?"
          message="Please note that after taking this action, your tag will be marked as closed. Make sure you have checked all the scenarios and confirmed the number."
          open={ConfirmDialogOpen}
          onClose={() => setConfirmDialogOpen(false)}
          onConfirm={() => {
            if (
              tagDetails.auditMade !== null &&
              tagDetails.countMade !== null &&
              uoms.length > 0 &&
              tagDetails.auditUOM !== null &&
              tagDetails.countUOM !== null
            ) {
              let value = tagDetails.countMade;
              let newUOM = tagDetails.countUOM;
              if (finalType == ReconciliationTypes.Auditer) {
                value = tagDetails.auditMade;
                newUOM = tagDetails.auditUOM;
              }

              setFormValues((prevValues) => ({
                ...prevValues,
                count: value.toString(),
                uom: newUOM,
              }));
              setErrors((prevErrors) => ({
                ...prevErrors,
                count: false,
                uom: false,
              }));

              if (uoms.length > 0) {
                const uOMId: number =
                  uoms.find((x) => x.name == newUOM)?.id ?? 0;
                updateReconcileDetails(value, uOMId);
              }
            }
            setConfirmDialogOpen(false);
          }}
        />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <Calculator
          open={openCalculator}
          onClose={handleCalculatorClose}
          onSubmit={handleCalculatorValue}
        />
      </Suspense>
    </div>
  );
};

export default ReconciliationTagTile;

import React, { useEffect, useState } from "react";
import styles from "./confirmPassword.module.scss";
import {
  mdiEyeOffOutline,
  mdiEyeOutline,
  mdiCheck,
  mdiClose,
  mdiAlertCircleOutline,
} from "@mdi/js";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Snackbar,
  TextField,
  Alert,
} from "@mui/material";
import Icon from "@mdi/react";
import { IConfirmPassword } from "../../interfaces";
import { loginService, userService } from "../../api/services";
import { useLocation, useNavigate } from "react-router";
import { decryptData } from "../../utils/crypto-js";
import { Loader } from "../../components";

const ConfirmPassword: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isFormValid, setIsFormValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error">("success");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [email, setEmail] = useState<string | null>("");
  const [userId, setUserId] = useState<string | null>();
  const [isExpired, setIsExpired] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const checkUrlExpiry = (expiryTimeFromUrl: number | null): boolean => {
    const currentTime = Math.floor(Date.now() / 1000); 
    return expiryTimeFromUrl ? currentTime > expiryTimeFromUrl : false;
  };
  
  useEffect(() => {
    const encryptedData = location.search.slice(1);
    if (encryptedData) {
      try {
        // Decrypt the data
        const decryptedData = decryptData(encryptedData);
        const params = new URLSearchParams(decryptedData);
        setUserId(params.get("userId"));
        setEmail(params.get("email"));
        const expiryTimeFromUrl = parseInt(params.get("expiryTime") || "0");
        if (checkUrlExpiry(expiryTimeFromUrl)) {
          setSnackbarMessage("The reset link has expired.");
          setSeverity("error");
          setIsDisabled(true);
          setOpenSnackbar(true);
          setIsExpired(true);
        }
    
      } catch (error) {
        console.error("Decryption failed:", error);
      }
    }
  }, [location]);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);
  const handleMouseDownConfirmPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const [isDisabled, setIsDisabled] = useState(false);
  const [formValues, setFormValues] = useState<IConfirmPassword>({
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({
    password: false,
    confirmPassword: false,
  });

  const isPasswordValid = (password: string): boolean => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/;
    return passwordRegex.test(password);
  };

  // Email validation function
  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Change event for input fields
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  // Blur event for input fields (for validation on blur)
  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setErrors((prevErrors) => ({
      ...prevErrors,
      password:
        name === "password" ? !isPasswordValid(value) : prevErrors.password,
      confirmPassword:
        name === "confirmPassword"
          ? value !== formValues.password
          : prevErrors.confirmPassword,
    }));
  };

  // Validate Form
  const validateForm = () => {
    const newErrors = {
      password: !isPasswordValid(formValues.password),
      confirmPassword: formValues.confirmPassword !== formValues.password,
    };
    setErrors(newErrors);
    return !Object.values(newErrors).includes(true);
  };

  // Close Snackbar
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // Dynamically assign custom icons based on severity
  const getIcon = () => {
    if (severity === "success") {
      return <Icon path={mdiCheck} size={1} />; // Custom success icon
    }
    return <Icon path={mdiAlertCircleOutline} size={1} />; // Custom error icon
  };

  // Handle Confirm Password
  const resetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsDisabled(true);
    setShowLoader(true);
   try {
      const payload = {
        userId: userId,
        newPassword: formValues.confirmPassword,
      };
      const response = await userService.forgotPassword(payload);
      if (response.isSuccess) {
        setSnackbarMessage("Password saved successfully.");
        setSeverity("success");
        setOpenSnackbar(true);
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
        }));
      }
    } catch (error: any) {
      const errorMessages = error.response?.data?.errors || [
        "Error occurred while changing password.",
      ];
      setSnackbarMessage(errorMessages.join(", "));
      setSeverity("error");
      setOpenSnackbar(true);
      setErrors({ ...errors });
    }finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    const isValid =
      formValues.password !== "" &&
      formValues.confirmPassword !== "" &&
      !errors.password &&
      !errors.confirmPassword;
    setIsFormValid(isValid);
  }, [formValues, errors]);

  return (
    <Grid container className={styles.full_height_section}>
      <Grid item sm={6} className={styles.login_left}>
        <div className={styles.login_left_content}>
          <h3> ICM </h3>
          <p>
            ICM is designed to bring your inventory count process into the 21st
            Century, empowering businesses to achieve greater accuracy and
            efficiency in managing physical inventory.
          </p>
        </div>
      </Grid>
      <Grid item sm={6} className={styles.login_right}>
        <div className={styles.login_right_content}>
          <h3> Reset Your Password </h3>
          <p>Enter a new password for {email}</p>
          <FormControl className="fieldWrapper" fullWidth>
            <TextField
              id="password"
              name="password"
              required
              disabled={isExpired}
              type={showPassword ? "text" : "password"}
              value={formValues.password}
              label="Password"
              placeholder="Enter Your Password"
              onChange={handleChange}
              onBlur={handleBlur}
              inputProps={{ maxLength: 16 }}
              error={errors.password}
              helperText={
                errors.password
                  ? "Password must include at least one uppercase letter, one lowercase letter, one number, one special character, no blank space allowed, and must be 8 characters long."
                  : ""
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <Icon path={mdiEyeOffOutline} size={0.7} />
                      ) : (
                        <Icon path={mdiEyeOutline} size={0.7} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <FormControl className="fieldWrapper" fullWidth>
            <TextField
              id="confirmPassword"
              name="confirmPassword"
              required
              disabled={isExpired}
              type={showConfirmPassword ? "text" : "password"}
              value={formValues.confirmPassword}
              label="Confirm Password"
              placeholder="Confirm Your Password"
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.confirmPassword}
              helperText={
                errors.confirmPassword
                  ? formValues.confirmPassword === ""
                    ? "Confirm password is required."
                    : "Passwords do not match."
                  : ""
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle confirm password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownConfirmPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? (
                        <Icon path={mdiEyeOffOutline} size={0.7} />
                      ) : (
                        <Icon path={mdiEyeOutline} size={0.7} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <Button
            id="login"
            fullWidth
            variant="contained"
            disabled={!isFormValid || isDisabled}
            onClick={resetPassword}
          >
            Reset Password
          </Button>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={openSnackbar}
          autoHideDuration={10000}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={severity}
            icon={getIcon()}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Grid>
      {showLoader && <Loader/>}
    </Grid>
  );
};

export default ConfirmPassword;
